import { Injectable } from '@angular/core'
import { PublishTrackingService } from '@services'
import { maxBy } from 'lodash'

@Injectable({ providedIn: 'root' })
export class EventsEndpointCheckService {
  // date the events endpoint went live
  private eventsEndpointLiveDate: string = new Date('2023-09-28T00:00:00Z').toISOString()

  constructor(private publishTrackingService: PublishTrackingService) {
    // noop
  }

  public async publishedAfterEndpoint(programId): Promise<boolean> {
    let lastPublish: string
    let publishTracking = this.publishTrackingService.getAll()

    if (!publishTracking.length) {
      publishTracking = await this.publishTrackingService.findAll({ program_id: programId })
    }

    lastPublish = maxBy(publishTracking, 'last_published_at').last_published_at.toString()

    return this.eventsEndpointLiveDate < lastPublish
  }
}
