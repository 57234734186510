<se-fe-grid seFeGridOpts="snug" data-cy="teams-grid">
<se-fe-grid-cell seFeGridOpts="1/1">
<header class="sm-header">
  <div>
    <span class="sticky-header__primary">{{ statModule.short_name }}</span>
    <span class="sticky-header__text">Stats</span>
  </div>
</header>
<se-fe-empty-state [seFeDataTitle]="'No Statistics'" [seFeDataSize]="500" *ngIf="!stats.length" data-cy="no-stats-available">
  No statistics available
</se-fe-empty-state>
<se-fe-table-wrapper>
  <table seFeTable seFeTableSort [title]="statModule.short_name" [hidden]="!stats.length" data-cy="stat-table">

    <ng-container seFeColumnDef="rank">
      <th seFeDataRef="rank" data-cy="rank-header" seFeHeaderCell *seFeHeaderCellDef></th>
      <td seFeCell *seFeCellDef="let tr, let i = index" data-cy="player-rank">{{ i + 1 }}</td>
    </ng-container>

    <ng-container *ngIf="statModule.type === 'StatModule'" seFeColumnDef="playerName">
      <th seFeDataRef="name" data-cy="player-name-header" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>Player Name</th>
      <td seFeCell *seFeCellDef="let tr" data-cy="player-name">{{ tr.name }}</td>
    </ng-container>

    <ng-container seFeColumnDef="team">
      <th seFeDataRef="team_name" data-cy="team-name-header" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>Team</th>
      <td seFeCell *seFeCellDef="let tr" data-cy="team-name-stats">
        <div class="team-logo-name">
          <se-fe-avatar seFeDataType="team"
            [seFeDataSport]="tr.team.season?.sportLogo"
            [seFeDataImgUrl]="tr.team.logo?.image_urls.medium"
            seFeDataSize="200"
            data-cy="stats-table-team-logo">
          </se-fe-avatar>
          <sm-nav-link
            url=".{{ team ? '.' : teamUrl }}/{{ tr.team.id }}"
            [queryParams]="{ tab: 'statistics' }"
            [label]="tr.team_name"
            tag="team-link-stats">
          </sm-nav-link>
        </div>
      </td>
    </ng-container>

    <ng-container seFeColumnDef="division">
      <th seFeDataRef="team.division.name" data-cy="division-name-header" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>Division</th>
      <td seFeCell *seFeCellDef="let tr" data-cy="division-name-stats">{{ tr.team.division.name }}</td>
    </ng-container>

    <ng-container *ngFor="let statType of prefModule.stat_types" data-cy="stat-header">
      <ng-container [seFeColumnDef]="statType.key">
        <th seFeDataRef="{{'values.' + prefModule.key +'.'+ statType.key }}" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef="let tr">          
          <sm-abbr tooltip="{{ statModule.statTypes[statType.key].name }}" data-cy="stat-header-abbrev">
            {{ statModule.statTypes[statType.key].abbrev }}
          </sm-abbr>
        </th>
        <td seFeCell *seFeCellDef="let tr">{{ displayStatValue(tr.values[prefModule.key][statType.key] || 0, statType.key) }}</td>
      </ng-container>
    </ng-container>

    <tr seFeHeaderRow *seFeHeaderRowDef="displayableColumns" data-cy="stats-header-row"></tr>
    <tr seFeRow *seFeRowDef="let tr; columns: displayableColumns" data-cy="stats-table-row"></tr>

  </table>
</se-fe-table-wrapper>
</se-fe-grid-cell>
<se-fe-grid-cell seFeGridOpts="1/1">
  <se-fe-pagination
  *ngIf="paginationOptions"
  [seFeDataPaginationOptions]="paginationOptions"
  (seFePaginationChange)="paginationChange($event)"> 
  </se-fe-pagination>
</se-fe-grid-cell>
</se-fe-grid>
