// tslint:disable:max-line-length
import { BrowserModule } from '@angular/platform-browser'
import { SeAnalyticsModule } from 'se-analytics'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { HttpClientModule } from '@angular/common/http'
import { FormsModule } from '@angular/forms'
import { environment } from 'src/environments/environment'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import {
  AppComponent, ScheduleComponent, Schedule2Component, StandingsComponent, TeamDetailComponent, DivisionStandingsComponent,
  SeasonComponent, TeamLogoComponent, LocationLinkComponent, StatisticsComponent, StatsTableComponent, TeamRosterComponent,
  DivisionDetailComponent, NavLinkComponent
} from '@components'
import { MomentDatePipe, AbbrComponent, GroupByPipe, HideWhenChildActiveDirective } from '@util'

import { ScheduleHeaderComponent } from './schedule/schedule-header.component'
import { ScheduleEventComponent } from './schedule/schedule-event.component'
import { ScheduleStatusComponent } from './schedule/schedule-status.component'
import { InfiniteScrollDirective } from './infinite-scroll/infinite-scroll.directive'
import { ListCardModule } from './list-card'

import { SeFeAvatarModule } from 'se-fe-avatar'
import { SeFeChipModule } from 'se-fe-chip'
import { SeFeEmptyStateModule } from 'se-fe-empty-state'
import { SeFeGridModule } from 'se-fe-grid'
import { SeFeHeaderModule } from 'se-fe-header'
import { SeFeIconModule, seFeIconChevronDown, seFeIconFilterList, seFeIconLocationOutline, seFeIconRefresh, seFeSportIcons } from 'se-fe-icon'
import { SeFeInlineListModule } from 'se-fe-inline-list'
import { SeFePaginationModule } from 'se-fe-pagination'
import { SeFeMenuModule } from 'se-fe-menu'
import { SeFeToolbarModule } from 'se-fe-toolbar'
import { SeFeTableModule } from 'se-fe-table'
import { SeFeSpinnerModule } from 'se-fe-spinner'
import { ColorService } from './services/color.service'

@NgModule({
  declarations: [
    AppComponent,
    ScheduleComponent,
    Schedule2Component,
    ScheduleHeaderComponent,
    ScheduleEventComponent,
    ScheduleStatusComponent,
    StandingsComponent,
    TeamDetailComponent,
    DivisionStandingsComponent,
    SeasonComponent,
    MomentDatePipe,
    TeamLogoComponent,
    AbbrComponent,
    LocationLinkComponent,
    GroupByPipe,
    HideWhenChildActiveDirective,
    InfiniteScrollDirective,
    StatisticsComponent,
    StatsTableComponent,
    TeamRosterComponent,
    DivisionDetailComponent,
    NavLinkComponent
  ],
  providers: [ColorService],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    ListCardModule,
    SeFeAvatarModule,
    SeFeChipModule,
    SeFeEmptyStateModule,
    SeFeGridModule,
    SeFeHeaderModule,
    SeFeIconModule.include([ ...seFeSportIcons, seFeIconChevronDown, seFeIconFilterList, seFeIconLocationOutline, seFeIconRefresh]),
    SeFeInlineListModule,
    SeFeMenuModule,
    SeFePaginationModule,
    SeFeTableModule,
    SeFeToolbarModule,
    SeFeSpinnerModule,
    SeAnalyticsModule.forRoot({ platProp: 'seasons_microsites', currentEnv: environment.name }),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
