import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core'
import { AnalyticsService } from '@services'
import { ColorService } from './services/color.service'

@Component({
  selector: 'sm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'season-microsites'
  @ViewChild('scrollbarMeasure') scrollbarMeasure: ElementRef
  private hostElement: HTMLElement

  constructor(
    private analyticsService: AnalyticsService,
    private elementRef: ElementRef,
    private colorService: ColorService,
  ) {
    colorService.init()
    document.addEventListener('click', event => analyticsService.handleClickEvent(event), { capture: true })
    this.hostElement = elementRef.nativeElement
  }
  ngAfterViewInit(): void {
    const el: HTMLElement = this.scrollbarMeasure.nativeElement
    this.hostElement.style.setProperty('--scrollbar-width', `${ el.offsetWidth - el.clientWidth }px`)
    el.remove()
  }
}
