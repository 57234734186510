import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { SeFeTableComponent, SeFeTableSortDirective } from 'se-fe-table'
import { TeamRoster, TeamRosterService } from '../services/team-roster.service'
import { Collection } from '../services/base-resource'

interface GroupBy {
  title: string
  isGroupBy: boolean
}

@Component({
  selector: 'sm-team-roster',
  templateUrl: './team-roster.component.html',
  styleUrls: ['./team-roster.component.scss']
})
export class TeamRosterComponent implements OnInit {

  @ViewChild(SeFeTableComponent) public table!: SeFeTableComponent<any>
  @ViewChild(SeFeTableSortDirective) public sorter!: SeFeTableSortDirective
  @Input() public rosterId: string
  @Input() public restrictInfo = true

  public displayableColumns = ['name', 'number', 'position']

  public loading = false
  public teams: any
  public roster: Collection<TeamRoster>
  public rosterMap: any
  public rosterGroupedBy: any
  public filteredRoster: any

  constructor(private teamRosterService: TeamRosterService) {
  }

  ngOnInit(): void {
    this.loadPage('')
  }

  public handleSearchQueryChange(query: string): void {
    this.filterRoster(query)
    this.rosterGroupedBy = this.groupRosterByStaffPlayers()
  }

  public async loadPage(query: string) {
    this.loading = true
    this.roster =  await this.teamRosterService.findAll({ roster_id: this.rosterId })
    this.filterRoster(query)
    this.rosterGroupedBy = this.groupRosterByStaffPlayers()
    this.loading = false
  }


  public isGroup(_index: number, item: GroupBy | any): boolean {
    return item.isGroupBy
  }

  private filterRoster(query: string): void {
    if (query) {
      this.filteredRoster = [...this.roster].filter(d => {
        return d.full_name.toLowerCase().includes(query.toLowerCase())
      })
    } else {
      this.filteredRoster = this.roster
    }
  }

  private groupRosterByStaffPlayers(): Array<any> {
    const players = [...this.filteredRoster].filter(r => r.roster_type === 'player').sort(this.sortByName)
    const staff = [...this.filteredRoster].filter(r => r.roster_type === 'staff').sort(this.sortByName)
    return [
      ...(players.length
        ? [
            {
              key: 'players',
              isGroupBy: true,
            },
          ]
        : []),
      ...players,
      ...(staff.length
        ? [
            {
              key: 'staff',
              isGroupBy: true,
            },
          ]
        : []),
      ...staff
    ]
  }

  private sortByName = (a, b) =>  {
    return a.full_name.localeCompare(b.full_name)
  }

}
