import { Component, Input } from '@angular/core'
import { Season } from '@services'

@Component({
  selector: 'sm-schedule-header',
  templateUrl: './schedule-header.component.html',
  styleUrls: ['./schedule-header.component.scss']
})
export class ScheduleHeaderComponent {

  @Input() public date: string

  public tz: string

  constructor() {
    this.tz = Season.current.timezone
  }

}
