import { Injectable, Injector } from '@angular/core'
import { startCase, pick } from 'lodash'
import * as moment from 'moment-timezone'
import { Memoize } from 'typescript-memoize'
import { environment } from 'src/environments/environment'
import {
  BaseModel, BaseResource, Season, SeasonService, Division, DivisionService,
  Team, TeamService, Venue, VenueService, BelongsTo
} from '@services'

// tslint:disable:variable-name
type GameTeam = {
  name: string
  short_name: string
  abbrev: string
  placeholder_id: number
  team_id: string
  season_team_id?: string
}

export class Game extends BaseModel {
  id: string
  game_id: string
  program_id: string
  flight_id: string
  flight_name: string
  subvenue_id: number
  subvenue_name: string
  venue_id: number
  venue_name: string
  starts_at: Date
  ends_at: Date
  tbd_time: boolean
  status: 'unscheduled' | 'scheduled' | 'in_progress' | 'completed' | 'postponed' | 'cancelled' | 'delayed' | 'deleted'
  sport_id: number
  scoring_type: 'unscored' | 'quick' | 'interval'
  scores: { [teamId: string]: number }
  interval_scores: { [teamId: string]: object }
  shootout_scores: { [teamId: string]: number }
  overtime: boolean
  shootout?: boolean
  home_team_id: string
  home_seed_id: number
  away_seed_id: number
  teams: GameTeam[]

  @BelongsTo(() => SeasonService, { localKey: 'program_id', memoize: true })
  season: Season

  @BelongsTo(() => DivisionService, { localKey: 'flight_id', memoize: true })
  division: Division

  @BelongsTo(() => VenueService, { localKey: 'venue_id', memoize: true })
  venue: Venue

  get homeTeam(): Team {
    return this.home_seed_id === null ? this.getInjected(TeamService).tbdTeam :
      this.getOrInjectTeam(this.teams.find((t) => t.placeholder_id === this.home_seed_id))
  }
  get awayTeam(): Team {
    return this.away_seed_id === null ? this.getInjected(TeamService).tbdTeam :
      this.getOrInjectTeam(this.teams.find((t) => t.placeholder_id === this.away_seed_id))
  }
  get homeTeamScore(): number {
    return this.scores[this.homeTeam?.team_id]
  }
  get awayTeamScore(): number {
    return this.scores[this.awayTeam?.team_id]
  }
  get displayStatus(): string {
    return startCase(this.status.replace('completed', 'final'))
  }

  @Memoize()
  get startDate(): string {
    return moment.tz(this.starts_at, this.season.timezone).format('YYYY-MM-DD')
  }

  @Memoize()
  public get displayDate(): string {
    return moment.tz(this.starts_at, this.season.timezone).format('ddd, MMM D')
  }

  @Memoize()
  public get displayTime(): string {
    if (this.tbd_time) return 'TBD'
    return moment.tz(this.starts_at, this.season.timezone).format('LT z')
  }

  otherTeam(team: Team): Team {
    return team === this.homeTeam ? this.awayTeam : this.homeTeam
  }

  isVictor(team: Team): boolean {
    switch (this.result(team)) {
      case 'W': return true
      case 'L': return false
    }
  }

  result(team: Team): string {
    if (this.status !== 'completed' || isNaN(this.homeTeamScore) || isNaN(this.awayTeamScore)) return ''
    const isHome = team === this.homeTeam
    if ((isHome && this.homeTeamScore > this.awayTeamScore) || (!isHome && this.awayTeamScore > this.homeTeamScore)) return 'W'
    if ((isHome && this.homeTeamScore < this.awayTeamScore) || (!isHome && this.awayTeamScore < this.homeTeamScore)) return 'L'
    return 'T'
  }

  private getOrInjectTeam(teamObj?: GameTeam): Team {
    if (!teamObj) return undefined
    const ts = this.getInjected(TeamService)
    const { season_team_id } = teamObj
    if (!season_team_id) {
      return ts.get(teamObj.team_id) ||
        ts.inject({
          id: teamObj.team_id,
          ...pick(this, 'program_id', 'flight_id'),
          ...pick(teamObj, 'name', 'short_name', 'abbrev', 'team_id'),
          isOutside: true
        } as Team)
    }
    return ts.get(season_team_id)
  }
}
// tslint:enable:variable-name

@Injectable({
  providedIn: 'root',
})
export class GameService extends BaseResource<Game> {
  public readonly endpoint = `${ environment.apiPath }/games`

  constructor(protected injector: Injector) {
    super(Game, injector)
  }
}
