<div *ngIf="loading" class="spinner">
  <img src="/assets/img/spinner.svg" alt="loading spinner">
</div>
<div *ngIf="!loading">
  <header [class.hidden]="noNav" class="sm-header">
    <div class="division">
      <h1 data-cy="division-name-details">{{ division.name }}</h1>
    </div>

    <nav class="sm-nav">
      <a routerLink="."
        [queryParams]="{ divisionTab: 'schedule' }"
        routerLinkActive
        #schedule="routerLinkActive"
        [class.active]="schedule.isActive"
        data-cy="schedule-tab">Schedule</a>
      <a routerLink="."
        [queryParams]="{ divisionTab: 'standings' }"
        routerLinkActive
        #standings="routerLinkActive"
        [class.active]="standings.isActive"
        [class.hidden]="!division.season.standings_preferences.enabled"
        data-cy="standings-tab">Standings</a>
      <a routerLink="."
        [queryParams]="{ divisionTab: 'statistics' }"
        routerLinkActive
        #statistics="routerLinkActive"
        [class.active]="statistics.isActive"
        [class.hidden]="!division.season.stat_preferences.enabled"
        data-cy="statistics-tab">Statistics</a>
    </nav>
  </header>

  <div id="schedule" [class.shown]="schedule.isActive">
    <h2 class="sm-page-title">Schedule</h2>
    <sm-schedule2 [hideDivisionFilter]="true" teamUrl="./../teams"></sm-schedule2>
  </div>

  <div id="standings" [class.shown]="standings.isActive" [class.hidden]="!division.season.standings_preferences.enabled">
    <h2 class="sm-page-title">Standings</h2>
    <sm-division-standings [division]="division" teamUrl="./../teams"></sm-division-standings>
  </div>

  <div id="statistics" [class.shown]="statistics.isActive" [class.hidden]="!division.season.stat_preferences.enabled">
    <h2 class="sm-page-title">Statistics</h2>
    <sm-statistics teamUrl="./../teams"></sm-statistics>
  </div>
</div>
