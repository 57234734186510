import { Injectable, Injector } from '@angular/core'
import { BaseModel, BaseResource, Id, QueryParams } from '@services'

// tslint:disable:variable-name
export type StandingsElement = {
  abbrev: string
  name: string
  key: string
  tooltip: string
  description: string
  format: 'integer' | 'decimal_3' | 'decimal_1'
}

export type ConfigurationElement = {
  name: string
  key: string
  default: number
  child: boolean
}

export class StandingsModule extends BaseModel {
  sport_id: number
  sport_name: string
  standings_elements: StandingsElement[]
  configuration_elements: ConfigurationElement[]
}
// tslint:enable:variable-name

@Injectable({
  providedIn: 'root'
})
export class StandingsModuleService extends BaseResource<StandingsModule> {
  public readonly endpoint = '/assets/data/standings-modules.json'

  constructor(protected injector: Injector) {
    super(StandingsModule, injector)
  }

  public async find(sportId: Id, _params?: QueryParams): Promise<StandingsModule> {
    await this.findAll() // injects all from json
    return this.get(sportId)
  }
}
