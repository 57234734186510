import { DOCUMENT } from '@angular/common'
import { Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'

@Component({
  selector: 'sm-abbr',
  template: `
    <ng-content></ng-content>
    <div #tooltipEl role="tooltip">{{ tooltip }}</div>
  `,
  styleUrls: ['./abbr.component.scss']
})
export class AbbrComponent implements OnInit, OnDestroy {

  public static SHOW_DELAY = 1500

  @Input() tooltip: string
  @ViewChild('tooltipEl') tooltipEl: ElementRef
  private element: HTMLElement
  public scrollParent: HTMLElement
  public leftOffset: number
  public tooltipShown = false
  private timeout
  private documentListeners: [type: string, fn: EventListener][] = []

  constructor(private ref: ElementRef, @Inject(DOCUMENT) private document: Document) {
    this.element = this.scrollParent = ref.nativeElement
  }

  ngOnInit(): void {
    this.addDocumentListener(['mousedown', 'touchstart'], (e: Event) => {
      if (this.tooltipShown && !this.element.contains(e.target as HTMLElement)) this.toggleTooltip(false)
    })
    this.addDocumentListener(['keydown'], (e: KeyboardEvent) => {
      if (/^Esc/.test(e.key)) this.toggleTooltip(false)
    })

    do this.scrollParent = this.scrollParent.parentElement
    while (this.scrollParent && !/auto|scroll|hidden/.test(getComputedStyle(this.scrollParent).overflowX))
    this.scrollParent ||= this.document.documentElement
  }

  ngOnDestroy(): void {
    this.documentListeners.forEach(([type, fn]) => this.document.removeEventListener(type, fn))
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.timeout = setTimeout(() => this.toggleTooltip(true), AbbrComponent.SHOW_DELAY)
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.toggleTooltip(false)
  }

  @HostListener('mousedown')
  toggleTooltip(shown: boolean = !this.tooltipShown) {
    clearTimeout(this.timeout)

    const prevScrollWidth = this.scrollParent.scrollWidth
    let prevTooltipWidth: number
    const tooltip: HTMLElement = this.tooltipEl.nativeElement

    tooltip.classList.toggle('show', this.tooltipShown = shown)
    do {
      prevTooltipWidth = tooltip.offsetWidth
      this.leftOffset = (this.element.offsetWidth - tooltip.offsetWidth) / 2
      tooltip.style.left = `${ this.leftOffset }px`
    }
    while (prevTooltipWidth !== tooltip.offsetWidth) // accounts for resizing due to position

    const widthDifference = this.scrollParent.scrollWidth - prevScrollWidth
    if (widthDifference > 0) { // we've bumped the scroll area without intending to, shift the offset and the arrow
      tooltip.style.left = `${ this.leftOffset -= widthDifference }px`
      this.element.style.setProperty('--arrow-offset', `${ widthDifference }px`)
    }
  }

  private addDocumentListener(types: string[], fn: EventListener): void {
    types.forEach(type => {
      this.documentListeners.push([type, fn])
      this.document.addEventListener(type, fn)
    })
  }

}
