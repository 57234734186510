import { DOCUMENT } from '@angular/common'
import { Component, Input, OnDestroy, AfterViewInit, ElementRef, Inject, ViewChild } from '@angular/core'
import { Memoize } from 'typescript-memoize'
import { Division, StandingsService } from '@services'
import { setWhile } from '@util'
import { clamp } from 'lodash'

@Component({
  selector: 'sm-division-standings',
  templateUrl: './division-standings.component.html',
  styleUrls: ['./division-standings.component.scss']
})
export class DivisionStandingsComponent implements AfterViewInit, OnDestroy {

  @Input() public division: Division
  @Input() public teamUrl = '/teams'
  @ViewChild('statTableWrapper') statTableWrapper: ElementRef
  @ViewChild('headerTable') headerTable: ElementRef
  @ViewChild('statTable') statTable: ElementRef
  public scroll: EventListener
  public loading = false

  get wrapperEl(): HTMLElement {
    return this.statTableWrapper?.nativeElement
  }
  get scrollable(): boolean {
    return this.wrapperEl?.scrollWidth > this.wrapperEl?.offsetWidth
  }
  get scrollableLeft(): boolean {
    return this.wrapperEl?.scrollLeft > 10
  }
  get scrollableRight(): boolean {
    return this.wrapperEl?.scrollLeft < this.wrapperEl?.scrollWidth - this.wrapperEl?.offsetWidth - 10
  }

  @Memoize()
  public get showTable() {
    return this.division.standings_preferences.enabled && this.division.teams.length
  }

  constructor(
    private standingsService: StandingsService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngAfterViewInit(): void {
    let lastTop = 0
    this.scroll = () => {
      const [header, table] = [this.headerTable, this.statTable].map(el => el?.nativeElement)
      if (!(header && table)) return
      const targetTop = lastTop + 36 - header.getBoundingClientRect().top
      lastTop = clamp(targetTop, 0, table.offsetHeight - 1)
      header.style.top = `${ lastTop }px`
    }
    this.document.addEventListener('scroll', this.scroll, true)
    this.scroll(null)
  }

  ngOnDestroy(): void {
    this.document.removeEventListener('scroll', this.scroll, true)
  }


  @setWhile('loading')
  async refresh(): Promise<void> {
    await this.standingsService.findAll({ program_id: this.division.program_id, flight_id: this.division.id })
  }

}
