import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { EventsEndpointCheckService, NoNavService } from '@services'
@Injectable({
  providedIn: 'root'
})
export class Schedule2Redirect {
  constructor(
    private router: Router,
    private eventsEndpointCheck: EventsEndpointCheckService,
    private noNavService: NoNavService
  ) {
    // noop
  }

  public async canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const programId = (state.url.match(/\/seasons\/*([^/]*)/))[1]
    const noNav = this.noNavService.get()
    const publishedAfterEndpoint = await this.eventsEndpointCheck.publishedAfterEndpoint(programId)
    if (publishedAfterEndpoint)  return true
    return this.router.parseUrl(`/seasons/${ programId }/schedule${ noNav ? '?noNav=true' : '' }`)
  }

}
