import { Injectable, Injector } from '@angular/core'
import { keyBy } from 'lodash'
import { Memoize } from 'typescript-memoize'
import { BaseModel, BaseResource } from '@services'

// tslint:disable:variable-name
export class StatModule extends BaseModel {
  id: string
  type: 'StatModule' | 'TeamStatModule'
  created_at: Date
  description: string
  key: string
  name: string
  ngin_sport_id: number
  ngin_stat_module_id: number
  position: number
  save_priority: number
  scoring_module: false
  short_name: string
  sport_id: number
  stat_types: StatType[]
  updated_at: Date

  @Memoize()
  get statTypes(): { [key: string]: StatType } {
    return keyBy(this.stat_types, 'key')
  }
}

export type StatType = {
  abbrev: string
  field_type: string
  formatter: string
  games_stat?: boolean
  key: string
  name: string
  player_only: boolean
  position: number
}
// tslint:enable:variable-name

@Injectable({
  providedIn: 'root'
})
export class StatModuleService extends BaseResource<StatModule> {
  public readonly endpoint = '/assets/data/stat-modules.json'

  constructor(protected injector: Injector) {
    super(StatModule, injector)
  }

  // Retrieve all the data from the static JSON file, strip out the top-level objects (kinda useless), and inject the data in stat_modules.
  public async findAll(): Promise<StatModule[]> {
    type tempStatModuleType = { id: number, sport_id: number, sport_name: string, stat_modules: StatModule[] }
    const result = await this.http.get<tempStatModuleType[]>(this.endpoint).toPromise()
    return this.inject(result.flatMap(sm => sm.stat_modules))
  }
}
