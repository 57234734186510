// nav-link.component.ts
import { Component, Input, OnInit } from '@angular/core'
import { Params, QueryParamsHandling } from '@angular/router'
import { NoNavService } from '../services/no-nav.service'

@Component({
  selector: 'sm-nav-link',
  templateUrl: './nav-link.component.html'
})
export class NavLinkComponent implements OnInit {
  @Input() url: string
  @Input() label: string
  @Input() queryParams: Params
  @Input() tag: string
  @Input() queryParamsHandling: QueryParamsHandling

  public noNav: boolean

  constructor(private noNavService: NoNavService) {}

  ngOnInit(): void {
    this.noNav = this.noNavService.get()
  }
}
