import { Injectable, Injector } from '@angular/core'
import { environment } from 'src/environments/environment'
import {
  BaseModel, BaseResource, Season, SeasonService,
  Division, DivisionService, StandingsTeamRecord, BelongsTo
} from '@services'

// tslint:disable:variable-name
export class Team extends BaseModel {
  id: string
  team_id: string
  team_service_id: string
  org_id: number
  program_id: string
  season_id: string
  season_name: string
  flight_id: string
  flight_name: string
  roster_id: string
  sport_id: number
  name: string
  short_name: string
  abbrev: string
  gender?: string
  logo?: {
    image_type?: string
    label: string
    image_urls: {
      tiny: string
      small: string
      medium: string
      large: string
      extra_large: string
      print: string
      medium_square: string
      large_square: string
      large_uncropped: string
    }
  }
  extended_attributes: { key: string, value: any }[]
  isOutside?: boolean

  @BelongsTo(() => SeasonService, { localKey: 'program_id', memoize: true })
  season: Season

  @BelongsTo(() => DivisionService, { localKey: 'flight_id', memoize: { tags: ['division'] } })
  division: Division

  get standingsRecord(): StandingsTeamRecord {
    const records = this.division?.standings?.teamRecords || []
    return records.find(r => r.team_id === this.team_id)
  }
  get standingsRecordDisplay(): string {
    const record = this.standingsRecord?.values || { w: 0, l: 0 }
    const values = [record.w || 0, record.l || 0]
    if (record.t) values.push(record.t)
    return `(${ values.join(' - ') })`
  }
}
// tslint:enable:variable-name

@Injectable({
  providedIn: 'root'
})
export class TeamService extends BaseResource<Team> {
  public readonly endpoint = `${ environment.apiPath }/teams`

  private _tbdTeam: Team
  public get tbdTeam(): Team {
    return this._tbdTeam ||= this.inject({
      id: null,
      name: 'TBD',
      program_id: this.injector.get(SeasonService).getAll()[0].id
    } as Team)
  }
  constructor(protected injector: Injector) {
    super(Team, injector)
  }
}
