import { Injectable, Injector } from '@angular/core'
import { Memoize } from 'typescript-memoize'
import { keyBy, sortBy, uniq } from 'lodash'
import moment from 'moment-timezone'
import { environment } from 'src/environments/environment'
import {
  BaseModel, BaseResource, StandingsPreferences, Division, DivisionService,
  StandingsElement, StandingsModule, StandingsModuleService, PublishTracking, PublishTrackingService,
  StatPreferences, StatPrefModule, StatModule, StatModuleService, BelongsTo, HasMany
} from '@services'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

// tslint:disable:variable-name
export class Season extends BaseModel {
  public static current: Season // to be set in season component and used globally
  public static currentPromise: Promise<Season>

  id: string
  season_id: string
  name: string
  description?: string
  type: 'Season' | 'Program' | 'League' | 'Club' | 'GoverningSeason' | 'Tournament'
  start_date: Date
  end_date: Date
  sport_id: number
  sport_key: string
  microsite_settings: any
  org_id: number
  timezone: string
  time_format?: string
  standings_preferences?: StandingsPreferences
  stat_preferences?: StatPreferences
  extended_attributes: { key: string, value: any }[]

  @Memoize()
  get sportLogo(): string {
    return `https://s3.amazonaws.com/sportngin-snap-production/ui_themes/assets/latest/images/sport-ball-icons/${ this.sport_key.replace('_', '-') }.svg`
  }
  @HasMany(() => DivisionService, { foreignKey: 'program_id', memoize: true })
  divisions: Division[]

  @BelongsTo(() => StandingsModuleService, { localKey: 'sport_id', memoize: true })
  standingsModule: StandingsModule

  @Memoize()
  get standingsElements(): { [key: string]: StandingsElement } {
    return keyBy(this.standingsModule.standings_elements, 'key')
  }
  @HasMany(() => StatModuleService, { localKey: 'sport_id', foreignKey: 'ngin_sport_id', memoize: true })
  stat_modules: StatModule[]

  @Memoize()
  get statModules(): { [key: string]: StatModule } {
    return keyBy(this.stat_modules, 'key')
  }

  @Memoize()
  get statPrefModules(): StatPrefModule[] {
    return sortBy(this.stat_preferences.stat_modules, sm => this.statModules[sm.key].position)
  }

  @Memoize()
  get yearDisplay(): string {
    const startYear = moment.tz(this.start_date, this.timezone).format('YYYY')
    const endYear = moment.tz(this.end_date, this.timezone).format('YYYY')
    return uniq([startYear, endYear]).join('-')
  }

  @HasMany(() => PublishTrackingService, { foreignKey: 'program_id' })
  publishTracking: PublishTracking[]
}
// tslint:enable:variable-name

@Injectable({
  providedIn: 'root'
})
export class SeasonService extends BaseResource<Season> {
  public readonly endpoint = `${ environment.apiPath }/seasons`
  public season: any
  constructor(protected injector: Injector) {
    super(Season, injector)
  }
  public async canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
    Season.currentPromise = this.find(routeSnapshot.params.programId)
    this.season = Season.current = await Season.currentPromise
  }

  public getCurrent(): Promise<Season> {
    return Season.currentPromise
  }

}
