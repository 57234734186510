/*

  EXAMPLE USAGE

  <div
    (smInfiniteScroll)="loadNextPage()"
    [smInfiniteScrollEnabled]="!lastPage && !loading"
    [smInfiniteScrollBuffer]="900">
  </div>

*/

import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core'

import debounce from 'lodash/debounce'
import { SeFeScrollService } from 'se-fe-scroll'

const RESIZE_DELAY = 100
const BUFFER = 1000 // px

@Directive({
  selector: '[smInfiniteScroll]'
})
export class InfiniteScrollDirective implements OnChanges, OnDestroy {

  @Input('smInfiniteScrollEnabled') public enabled = false
  @Input('smInfiniteScrollReset') public reset = false
  @Input('smInfiniteScrollBuffer') public buffer = BUFFER
  @Output() public smInfiniteScroll = new EventEmitter<void>()
  @HostListener('window:resize') private onResize = debounce(() => this.ngOnChanges(), RESIZE_DELAY)

  private scrollParent: HTMLElement

  constructor(
    private el: ElementRef,
    private scrollService: SeFeScrollService) {
    // noop
  }

  public ngOnChanges(): void {
    if (this.reset && this.scrollParent) {
      this.scrollService.scrollTopTo(this.scrollParent, 0)
      setTimeout(() => this.reset = false)
    }
    // update after timeout to allow for rendering
    if (this.enabled) {
      setTimeout(() => {
        this.bindScrollParent()
        this.checkScroll()
      })
    }
  }

  public ngOnDestroy(): void {
    if (!this.scrollParent) return
    this.unbindParentScroll(this.scrollParent)
  }

  private bindScrollParent = (): void => {
    const prevScrollParent = this.scrollParent
    const scrollParent = this.scrollParent = this.scrollService.scrollParent(this.el.nativeElement) as HTMLElement
    if (prevScrollParent === scrollParent) return
    if (prevScrollParent) this.unbindParentScroll(prevScrollParent)
    scrollParent.addEventListener('scroll', this.checkScroll)
    this.scrollService.preventAutoScroll(scrollParent)
  }

  private unbindParentScroll(el: HTMLElement): void {
    el.removeEventListener('scroll', this.checkScroll)
  }

  private checkScroll = (): void => {
    if (!this.enabled || this.scrollBottom() >= this.buffer) return
    this.smInfiniteScroll.emit()
  }

  private scrollTop(): number {
    return this.scrollService.scrollTop(this.scrollParent)
  }

  private scrollBottom(): number {
    const height = this.scrollService.height(this.scrollParent)
    const scrollHeight = this.scrollParent.scrollHeight || document.documentElement.scrollHeight // window has no scrollHeight
    return scrollHeight - height - this.scrollTop()
  }

}
