import { BaseModel, BaseResource, BaseResourceType } from '@services'
import { Memoize } from 'typescript-memoize'

type ResourceGetter = () => BaseResourceType
type RelationOpts = {
  localKey?: string
  foreignKey?: string
  memoize?: boolean | { tags?: string[] }
}
function getMemoizedDescriptor(
  memoize: RelationOpts['memoize'],
  target: object,
  propertyKey: string,
  descriptor: TypedPropertyDescriptor<any>
): TypedPropertyDescriptor<any> {
  if (memoize) Memoize(memoize)(target, propertyKey, descriptor) // will override get in-place
  return descriptor
}
function getDecorator(resource: ResourceGetter, opts: RelationOpts, filterFn: (res: BaseResource<any>) => any): DecoratorFunction {
  return (target: BaseModel, key: string) => {
    Object.defineProperty(target, key, getMemoizedDescriptor(opts.memoize, target, key, {
      get() { return filterFn.call(this, this.getInjected(resource())) }
    }))
  }
}
function lookup(context, opts: RelationOpts) {
  return obj => obj[opts.foreignKey] === context[opts.localKey || 'id']
}
type DecoratorFunction = (target: object, key: string) => void

export function BelongsTo(resource: ResourceGetter, opts: RelationOpts): DecoratorFunction {
  return getDecorator(resource, opts, function(res) {
    return res.get(this[opts.localKey])
  })
}
export function HasOne(resource: ResourceGetter, opts: RelationOpts): DecoratorFunction {
  return getDecorator(resource, opts, function(res) {
    return res.getAll().find(lookup(this, opts))
  })
}
export function HasMany(resource: ResourceGetter, opts: RelationOpts): DecoratorFunction {
  return getDecorator(resource, opts, function(res) {
    return res.getAll(lookup(this, opts))
  })
}
