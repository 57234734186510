<se-fe-spinner *ngIf="loading" [seFeDataSize]="900" [seFeDataCenter]="true"></se-fe-spinner>
<se-fe-grid seFeGridOpts="snug" data-cy="teams-grid" *ngIf="!loading">
  <se-fe-grid-cell seFeGridOpts="1/1" *ngIf="!roster.length">
    <se-fe-empty-state [seFeDataTitle]="'No Roster'" [seFeDataSize]="500" data-cy="roster-empty-state">
      Please add players and staff to team
    </se-fe-empty-state>
  </se-fe-grid-cell>
  <se-fe-grid-cell seFeGridOpts="1/1" *ngIf="roster.length > 0">
    <ng-container>
      <se-fe-toolbar
        [seFeDataSearchMinLength]="2"
        (seFeQueryChange)="handleSearchQueryChange($event)"
        data-cy="roster-search">
      </se-fe-toolbar>
    </ng-container>
    <table
      *ngIf="!loading"
      class="roster-table"
      seFeTable
      [title]="'team roster table'"
      [dataSource]="rosterGroupedBy">

      <ng-container seFeColumnDef="name">
        <th seFeHeaderCell *seFeHeaderCellDef>Name</th>
        <td seFeCell *seFeCellDef="let row">
          <span class="roster-persona-avatar" data-cy="roster-persona">
            <se-fe-avatar
              *ngIf="!restrictInfo"
              seFeDataType="persona"
              [seFeDataIdentity]="row.full_name"
              [seFeDataImgUrl]="row.headshot?.image_urls.small"
              seFeDataSize="200"
              data-cy="headshot">
            </se-fe-avatar>
            {{ row.full_name }}
          </span>
        </td>
      </ng-container>
      <ng-container seFeColumnDef="number">
        <th seFeHeaderCell *seFeHeaderCellDef>Number</th>
        <td seFeCell *seFeCellDef="let row">
          <ng-container *ngIf="row.roster_type === 'player'; else staff" data-cy="jersey-number">
            {{ row.jersey_number }}
          </ng-container>
          <ng-template #staff>
            --
          </ng-template>
        </td>
      </ng-container>

      <ng-container seFeColumnDef="position">
        <th seFeHeaderCell *seFeHeaderCellDef>Position</th>
        <td seFeCell *seFeCellDef="let row">
          <ng-container *ngIf="row.roster_type === 'player'; else staff" data-cy="position">
            {{ row.positions?.join(', ') }}
          </ng-container>
          <ng-template #staff>
            {{ row.title }}
          </ng-template>
        </td>
      </ng-container>

      <tr seFeHeaderRow *seFeHeaderRowDef="displayableColumns"></tr>
      <tr seFeRow *seFeRowDef="let row; columns: displayableColumns"></tr>

      <ng-container seFeColumnDef="groupHeader">
        <td [attr.colspan]="displayableColumns.length" seFeCell *seFeCellDef="let groupHeader">
          {{ groupHeader.key | uppercase }}
        </td>
      </ng-container>

      <tr seFeRow *seFeRowDef="let groupHeader; columns: ['groupHeader']; when: isGroup"></tr>
    </table>
  </se-fe-grid-cell>
</se-fe-grid>
