import { formatNumber } from '@angular/common'
import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { matches, pick, pickBy } from 'lodash'
import { Memoize } from 'typescript-memoize'
import {
  SeasonPlayerStat, SeasonPlayerStatsService, SeasonTeamStat,
  SeasonTeamStatsService, Season, StatPrefModule, Team
} from '@services'
import { StatFilterParams } from '@components'
import { SeFePaginationChange, SeFePaginationOptions } from 'se-fe-pagination'
import { SeFeTableComponent, SeFeTableDataSource, SeFeTableSortDirective } from 'se-fe-table'
import _ from 'lodash'
import { Subject } from 'rxjs'

type TableData = SeasonPlayerStat | SeasonTeamStat
type TableDataService = SeasonPlayerStatsService | SeasonTeamStatsService
const PAGINATION_LIMITS = [10, 25, 50]
const DEFAULT_PAGINATION = { page: 1, per_page: 25 }

@Component({
  selector: 'sm-stats-table',
  templateUrl: './stats-table.component.html',
  styleUrls: ['./stats-table.component.scss']
})
export class StatsTableComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
  @Input() public teamUrl = '/teams'

  @Input() prefModule: StatPrefModule
  @Input() filters: StatFilterParams
  @Input() team: Team
  @ViewChild(SeFeTableComponent) public table!: SeFeTableComponent<ElementRef>
  @ViewChild(SeFeTableSortDirective) public sorter!: SeFeTableSortDirective

  stats: SeasonPlayerStat[] | SeasonTeamStat[]
  tableData: TableData[]
  tableDataService: TableDataService
  public pagination: any
  public paginationOptions: SeFePaginationOptions
  public paginationParams = { ...DEFAULT_PAGINATION }
  season = Season.current
  public dataSource: SeFeTableDataSource
  public displayableColumns = [
    'team',
    'division',
  ]
  private componentDestroyed = new Subject<boolean>()

  ngOnInit(): void {
    this.displayableColumns = _.compact([
      'rank',
      (this.statModule.type === 'StatModule')
      ? 'playerName'
      : null, ...this.displayableColumns, ...this.prefModule.stat_types.map(statType => statType.key)])
  }

  public ngAfterViewInit(): void {
    this.setDataSource()
    if (this.stats.length) this.table.dataSource = this.dataSource
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(true)
    this.componentDestroyed.complete()
  }

  @Memoize()
  get statModule() {
    return this.season.statModules[this.prefModule.key]
  }

  constructor(
    private seasonPlayerStatsService: SeasonPlayerStatsService,
    private seasonTeamStatsService: SeasonTeamStatsService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setDataSource()
    if (this.stats.length && this.table) this.table.dataSource = this.dataSource
  }

  public setDataSource(): void {
    this.getSeasonStats()
    this.updatePaginationOptions()
    this.tableData = this.paginateSeasonTeamStats()
    this.dataSource = new SeFeTableDataSource(this.tableData, this.sorter)
  }

  public paginationChange(data: SeFePaginationChange): void {
    this.paginationParams.page = data.currentPage
    this.paginationParams.per_page = data.limit
    this.setDataSource()
    this.table.dataSource = this.dataSource
  }

  displayStatValue(value: string, statTypeKey: string): string {
    const formatter = this.statModule.stat_types.find(st => st.key === statTypeKey).formatter
    const numberValue = Number(value)

    switch (formatter)
    {
      case 'minutes_and_seconds':
        return this.displayMinutesAndSeconds(numberValue)
      case 'float_1':
        return formatNumber(numberValue, 'en-US', '1.1-1')
      case 'float_2':
        return formatNumber(numberValue, 'en-US', '1.2-2')
      case 'float_3':
        return formatNumber(numberValue, 'en-US', '1.3-3')
      case 'float_or_decimal_3':
        return formatNumber(numberValue, 'en-US', '1.3-3')
      case 'decimal_3':
        return formatNumber(numberValue, 'en-US', '1.3-3')
      case 'percentage':
        return formatNumber(numberValue, 'en-US', '1.3-3')
      default:
        return value
    }
  }

  displayMinutesAndSeconds(value: number): string {
    const minutes = Math.floor(value / 60).toString().padStart(2, '0')
    const seconds = (value % 60).toString().padStart(2, '0')
    return `${minutes}:${seconds}`
  }

  private getSeasonStats(): void {
    this.tableDataService = this.statModule.type === 'TeamStatModule' ? this.seasonTeamStatsService : this.seasonPlayerStatsService
    const matcher = matches(this.team ? { team_id: this.team.team_id } : pickBy(pick(this.filters, 'flight_id', 'team_id')))
    this.stats =  this.tableDataService.getAll(stat => this.statModule.key in stat.values && matcher(stat))
  }

  private paginateSeasonTeamStats(): SeasonTeamStat[] {
    const limit = this.paginationParams.per_page
    const start = (this.paginationParams.page - 1) * limit
    return this.stats.slice(start, start + limit)
  }

  private updatePaginationOptions = () => {
    const currentPage = this.paginationParams.page
    const total = this.stats.length
    const limit = this.paginationParams.per_page

    this.paginationOptions = {
      name: 'stats',
      label: 'Stats',
      currentPage,
      total,
      limit,
      limits: PAGINATION_LIMITS,
    }
  }
}
