<div *ngIf="loading" class="spinner">
  <img src="/assets/img/spinner.svg" alt="loading spinner">
</div>
<div *ngIf="!loading">
  <header *ngIf="!noNav" class="sm-page-header" [smHideWhenChildActive]>
    <se-fe-header
      [seFeDataTitle]="season.name"
      [seFeDataSubtitle]="seasonSubtitle"
      seFeDataSize="750">
    </se-fe-header>
    <nav class="sm-nav">
      <a [routerLink]="scheduleRouterLink" routerLinkActive="active" data-cy="schedule-tab">Schedule</a>
      <a *ngIf="season.standings_preferences.enabled" routerLink="standings" routerLinkActive="active" data-cy="standings-tab">Standings</a>
      <a *ngIf="season.stat_preferences.enabled" routerLink="statistics" routerLinkActive="active" data-cy="stats-tab">Statistics</a>
    </nav>
  </header>
  <div class="sm-page-content">
    <router-outlet></router-outlet>
  </div>
</div>
