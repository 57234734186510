import { HttpClient } from '@angular/common/http'
import { HttpTestingController } from '@angular/common/http/testing'
import { InjectionToken, Injector } from '@angular/core'
import { TestBed, tick } from '@angular/core/testing'
import { Memoize } from 'typescript-memoize'

export interface PaginationMetadata {
  current_page: number,
  first_page: number,
  last_page: boolean,
  limit: number,
  offset: number,
  total: number,
  total_pages: number,
}

export interface Collection<T> extends Array<T> {
  pagination?: PaginationMetadata
}

// Use in tests for dependent services to populate cache.
// ex. await injectAll(DivisionService, divisionJson)
export async function injectAll(serviceClass, response): Promise<any> {
  const http = TestBed.inject(HttpTestingController)
  const serviceInstance = TestBed.inject<typeof serviceClass>(serviceClass) // as BaseResource<any>
  response = JSON.parse(JSON.stringify(response)) // clone in order to preserve original object
  const result = serviceInstance.findAll()
  http.expectOne(serviceInstance.endpoint).flush(response)
  return await result
}

export type Id = string | number | null
export type QueryParams = { [param: string]: string | string[] }
export type FindOptions = { bypassCache?: boolean, params?: QueryParams }
export abstract class BaseModel {
  constructor(public injector: Injector) { }
  public id: Id

  @Memoize()
  getInjected(resource: any): typeof resource {
    return this.injector.get(resource as InjectionToken<any>) as typeof resource
  }
}

export abstract class BaseResource<DataType extends BaseModel> {
  public static testDataPath: string
  private store = new Map<Id, DataType>()
  public readonly endpoint: string
  private dataClass: new (injector: Injector) => DataType
  protected injector: Injector
  protected http: HttpClient

  constructor(dataClass: new (injector: Injector) => DataType, injector: Injector) {
    this.dataClass = dataClass
    this.injector = injector
    this.http = injector.get(HttpClient)
  }

  public deserialize(resp: any): (DataType | Collection<DataType>) {
    if (!resp.result) {
      return resp
    }
    const pagination = resp.metadata?.pagination
    if (pagination) resp.result.pagination = pagination
    return resp.result
  }

  public inject(obj: DataType): DataType
  public inject(obj: Collection<DataType>): Collection<DataType>
  public inject(obj: DataType | Collection<DataType>) {
    if (Array.isArray(obj)) {
      const collection = obj.map(o => this.inject(o)) as Collection<DataType>
      collection.pagination = obj.pagination
      return collection
    } else {
      const model = Object.assign(new this.dataClass(this.injector), obj)
      this.store.set(model.id, model)
      return model
    }
  }

  public get(id?: Id): DataType {
    return (id || id === null) ? this.store.get(id) : undefined
  }

  public getAll(filterFn?: (obj: DataType) => boolean): Collection<DataType> {
    return new Array<DataType>(...this.store.values()).filter(filterFn || (() => true))
  }

  public async find(id: Id, { bypassCache, params }: FindOptions = {}): Promise<DataType> {
    if (!id || !bypassCache && this.get(id)) return this.get(id)
    const result = await this.http.get<DataType>(`${ this.endpoint }/${ id }`, { params }).toPromise()
    return this.inject(this.deserialize(result) as DataType)
  }

  public async findAll(params?: QueryParams): Promise<Collection<DataType>> {
    const result = await this.http.get<Collection<DataType>>(this.endpoint, { params }).toPromise()
    return this.inject(this.deserialize(result) as Collection<DataType>)
  }
}
export type BaseResourceType = new (...args: any[]) => BaseResource<any>
