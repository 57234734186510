// tslint:disable:max-line-length
import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router'
import { Memoize } from 'typescript-memoize'
import { setWhile } from '@util'
import { chunk, startCase } from 'lodash'
import { Game, GameService, Team, TeamService, Season, EventsEndpointCheckService, MicrositeEventService, MicrositeEvent, NoNavService } from '@services'

@Component({
  selector: 'sm-team-detail',
  templateUrl: './team-detail.component.html',
  styleUrls: ['./team-detail.component.scss']
})
export class TeamDetailComponent implements OnInit {

  loading = true
  standalone: boolean
  parentTitle: string
  season = Season.current
  team: Team
  teams: Team[] = []
  teamEvents: Game [] | MicrositeEvent[]
  events: any = []
  showEmbedWindow = false
  showCopyFeedback = { link: false, embed: false }
  noNav: boolean

  pagedEvents: any[][]
  page = 0
  pageSize: number
  pageSizes = [5, 10, 20]
  restrictPersonalInfo: boolean

  get pageDisplay(): string {
    return `${ this.page * this.pageSize + 1 } – ${ Math.min((this.page + 1) * this.pageSize, this.events.length) }`
  }

  @Memoize()
  get showShareButton(): boolean {
    return !!navigator.share
  }

  get pagePath(): string {
    return this.location.path().replace(/\/(schedule|standings|roster)[^/]*|\?.*$/g, '')
  }
  get pageLink(): string {
    return window.location.origin + this.pagePath
  }
  get embedCode(): string {
    return `<script type="text/javascript" src="${ window.location.origin }/assets/external.js?path=${ this.pagePath }"></script>`
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private endpointDateCheck: EventsEndpointCheckService,
    private location: Location,
    private teamService: TeamService,
    private gameService: GameService,
    private eventService: MicrositeEventService,
    private noNavService: NoNavService
  ) {
    this.season = Season.current
  }

  ngOnInit(): void {
    const { route, router } = this
    if (!route.snapshot.queryParamMap.get('tab')) {
      router.navigate([], { relativeTo: route, queryParams: { tab: 'schedule' }, replaceUrl: true })
    }
    route.params.subscribe(p => this.loadTeam(p.teamId))
    route.parent.data.subscribe(d => {
      this.standalone = d.isRoot
      this.parentTitle = d.title
    })

    // Browser can fail access to localStorage in embed mode, fall back if so
    try { this.pageSize = +window.localStorage.getItem('pageSize') || 10 }
    catch (e) { this.pageSize = 10 }

    this.noNav = this.noNavService.get()
    // ! Need to be use to fetch [logo] in [event] type list.
    this.teams = this.teamService.getAll()
  }

  @setWhile('loading')
  async loadTeam(teamId: string): Promise<void> {
    this.team = await this.teamService.find(teamId)
    const publishedAfterEndpoint = await this.endpointDateCheck.publishedAfterEndpoint(this.team.program_id)
    if (publishedAfterEndpoint) {
      this.teamEvents = await this.eventService.findAll({
        program_id: this.team.program_id,
        team_id: this.team.team_service_id,
        order_by: 'starts_at',
        per_page: '1000'
      })
    }
    else {
      this.teamEvents = await this.gameService.findAll({
        program_id: this.team.program_id,
        team_id: this.team.team_id,
        order_by: 'starts_at'
      })
    }

    this.restrictPersonalInfo = this.isPrivate(this.season.microsite_settings.rosters)
    this.processEventGameData(this.teamEvents)
    this.pagedEvents = chunk(this.events, this.pageSize)
  }

  public processEventGameData(data: Game[] | MicrositeEvent[]) {
    if (data[0] instanceof Game) {
      this.events = data.map(game => this.mapGameData(game))
    } else {
      this.events = data.map(event => this.mapEventData(event))
    }
  }

  private mapEventData(event) {
    const isHome = this.team.id === event.homeTeam?.id
    const homeTeamScore = event.source.extended_attributes.home_team_score
    const awayTeamScore = event.source.extended_attributes.away_team_score
    const result = event.result(this.team)
    let _otherTeams = event.teams.filter(t => t.originator_id !== this.team.id)

    if (event.event_type === 'game' && !_otherTeams.length) {
      _otherTeams = [this.teamService.tbdTeam]
    }

    return {
      displayStatus: startCase(event.status.replace('completed', 'final')),
      displayDate: event.displayDate,
      displayTime: event.displayTime,
      event_type: event.event_type,
      title: event.title,
      description: event.description,
      homeTeam: event.homeTeam,
      result,
      resultAttr: this.resultAttr(result),
      opponent_score: isHome ? awayTeamScore : homeTeamScore,
      otherTeams: this.mapOtherTeams(_otherTeams),
      scored: !event.unscored,
      sport_logo: this.season.sport_key,
      status: event.status,
      subvenue_name: event.location_description,
      team_score: isHome ? homeTeamScore : awayTeamScore,
      venue_name: event.location_name,
      venue: event.venue
    }
  }

  private mapOtherTeams(teams: any): any {
    return teams.map(t => {
      // TBD team does not need to be mapped
      if (t === this.teamService.tbdTeam) return t

      // ! Using existing `teams` from instance to get complete [team] info.
      // ? Needed to fetch logo object.
      // ! Since an [event] doesn't have a direct access to [logo] then [teams] fix it.
      // ! Copy of [Schedule2Component:mapTeam] solution.
      const teamInstance = this.teams.find(({ id }) => t.originator_id === id) ?? null

      return {
        id: t.id,
        is_outside: t.extended_attributes?.outside_team,
        team_id: t.originator_id,
        name: t.extended_attributes?.name,
        primary_color: t.extended_attributes?.primary_color,
        logo: teamInstance?.logo,
      }
    })
  }

  private resultAttr(result: string): string {
    switch (result) {
      case 'W': return 'team-win'
      case 'L': return 'team-loss'
      case 'T': return 'team-tie'
      default: return 'game-in-progress'
    }
  }

  private mapGameData(game): any {
    const _otherTeam = game.otherTeam(this.team)
    const result = game.result(this.team)
    return {
      displayStatus: game.displayStatus,
      displayDate: game.displayDate,
      displayTime: game.displayTime,
      event_type: 'game',
      homeTeam: game.homeTeam,
      result,
      resultAttr: this.resultAttr(result),
      opponent_score: game.scores[_otherTeam.team_id],
      otherTeams: [{
        id: _otherTeam.id,
        is_outside: _otherTeam.isOutside || undefined,
        team_id: _otherTeam.team_id,
        name: _otherTeam.name,
        primary_color: null,
        logo: _otherTeam.logo,
      }],
      sport_logo: this.season.sport_key,
      status: game.status,
      team_score: game.scores[this.team.team_id],
      venue_name: game.venue_name,
      subvenue_name: game.subvenue_name,
      venue: game.venue
    }
  }

  async copyLink(): Promise<void> {
    await navigator.clipboard.writeText(this.pageLink)
    this.showCopyFeedback.link = true
    setTimeout(() => this.showCopyFeedback.link = false, 2000)
  }

  isPrivate(setting: string): boolean {
    return setting === 'private'
  }

  shareLink(): Promise<void> {
    return navigator.share({
      title: `${ this.team.name } Schedule and Standings`,
      text: `${ this.team.season.yearDisplay } season schedule and standings for ${ this.team.name }`,
      url: this.pageLink
    })
  }

  async copyEmbed(): Promise<void> {
    await navigator.clipboard.writeText(this.embedCode)
    this.showCopyFeedback.embed = true
    setTimeout(() => this.showCopyFeedback.embed = this.showEmbedWindow = false, 1000)
  }

  setPageSize(): void {
    try { window.localStorage.setItem('pageSize', String(this.pageSize)) }
    catch (e) { /* noop */ }

    this.pagedEvents = chunk(this.events, this.pageSize)
    this.page = 0
  }

}
