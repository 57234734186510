<div
  [smHideWhenChildActive]="['/teams']"
  (smInfiniteScroll)="loadPage(page + 1)"
  [smInfiniteScrollEnabled]="!loading && !lastPage"
  [smInfiniteScrollBuffer]="900"
  [smInfiniteScrollReset]="resetScroll"
  class="sm-schedule__events">

  <sm-list-filters
    [route]="route"
    [date]="true"
    [divisions]="divisions"
    [hideDivisionFilter]="hideDivisionFilter"
    [teams]="teams"
    [locations]="venues"
    [eventType]="true"
    (filterChange)="filterChange($event)">
  </sm-list-filters>

  <se-fe-grid seFeGridOpts="snug" *ngIf="!mappedEvents.length && eventsLoaded">
    <se-fe-grid-cell seFeGridOpts="1/1">
      <se-fe-empty-state seFeDataTitle="You don't have anything scheduled yet" [seFeDataSize]="500" data-cy="schedule-empty-state">
        Please add games or events, or publish your schedule
      </se-fe-empty-state>
    </se-fe-grid-cell>
  </se-fe-grid>

  <ng-container *ngFor="let event of mappedEvents; let i = index">
    <sm-schedule-header *ngIf="!i || event.start_date !== events[i-1].startDate" [date]="event.start_date"></sm-schedule-header>
    <sm-schedule-event [event]="event" [teamUrl]="teamUrl"></sm-schedule-event>
  </ng-container>

</div>

<router-outlet></router-outlet>
