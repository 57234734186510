import { Component, ElementRef, HostBinding, Inject, Input, OnInit } from '@angular/core'
import { startCase, toLower } from 'lodash'

@Component({
  selector: 'sm-schedule-status',
  template: '{{ displayStatus }}',
  styleUrls: ['./schedule-status.component.scss']
})
export class ScheduleStatusComponent implements OnInit {
  @Input() public event: any
  @HostBinding('attr.data-cy') public cy = 'event-status'

  public displayStatus: string

  constructor(@Inject(ElementRef) private el: ElementRef) {}

  ngOnInit(): void {
    const status = this.event.status
    this.displayStatus = startCase(toLower(this.event.type === 'event' ? status : status.replace('completed', 'final')))
    this.el.nativeElement.classList.add(`sm-schedule-status--${status}`)
  }
}
