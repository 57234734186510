<div [smHideWhenChildActive]>
  <header class="sm-header">
    <section #filterSection class="filters">
      <button type="button"
              class="sm-button toggle highlight"
              (click)="filterSection.classList.toggle('expanded')">
        Filters
      </button>
      <div class="filter">
        <label>Division</label>
        <select class="sm-select"
                [(ngModel)]="selectedDivision"
                id="divisionFilter"
                (change)="setFilters()"
                data-cy="division-filter-standings">
          <option [ngValue]="undefined">All Divisions</option>
          <option *ngFor="let division of allDivisions"
                  [ngValue]="division">{{ division.name }}</option>
        </select>
      </div>
    </section>
  </header>

  <sm-division-standings *ngFor="let division of divisions" [division]="division"></sm-division-standings>
</div>

<router-outlet></router-outlet>
