import { Injectable, Injector } from '@angular/core'
import { environment } from 'src/environments/environment'
import {
  BaseModel, BaseResource, Season, SeasonService, StandingsPreferences,
  Standings, StandingsService, Team, TeamService, BelongsTo, HasMany, HasOne
} from '@services'

// tslint:disable:variable-name
export class Division extends BaseModel {
  id: string
  name: string
  program_id: string
  description?: string
  standings_preferences?: StandingsPreferences
  microsite_settings: {
    standings: boolean
    scores: boolean
  }
  extended_attributes: { key: string, value: any }[]

  @BelongsTo(() => SeasonService, { localKey: 'program_id', memoize: true })
  season: Season

  @HasOne(() => StandingsService, { foreignKey: 'flight_id' })
  standings: Standings

  @HasMany(() => TeamService, { foreignKey: 'flight_id', memoize: true })
  teams: Team[]
}
// tslint:enable:variable-name

@Injectable({
  providedIn: 'root'
})
export class DivisionService extends BaseResource<Division> {
  public readonly endpoint = `${ environment.apiPath }/divisions`

  constructor(protected injector: Injector) {
    super(Division, injector)
  }
}
