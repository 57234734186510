import { Injectable } from '@angular/core'
import { ActivationEnd, NavigationCancel, NavigationEnd, Router, RouterEvent, RoutesRecognized } from '@angular/router'
import { SeAnalyticsService } from 'se-analytics'
import { camelCase } from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  pageDepths: any = {}
  navCanceled: boolean

  constructor(
    private router: Router,
    private seAnalyticsService: SeAnalyticsService
  ) {
    this.router.events.subscribe(this.handleRouterEvent.bind(this))
  }

  private handleRouterEvent(event: RouterEvent) {
    if (event instanceof RoutesRecognized) {
      this.pageDepths = {}
      this.navCanceled = false
    } else if (event instanceof NavigationCancel) {
      this.navCanceled = true
    } else if (event instanceof ActivationEnd) {
      this.captureDepths(event)
    } else if (event instanceof NavigationEnd) {
      this.triggerPageView()
    }
  }

  private captureDepths(event: ActivationEnd) {
    this.pageDepths.depth1 = 'SeasonMicrosites'
    this.pageDepths.depth2 = this.pageDepths.depth2 || event.snapshot.data.title
    if (this.router.url.includes('/teams')) {
      this.pageDepths.depth3 = 'TeamDetails'
    }
  }

  private triggerPageView() {
    if (this.navCanceled) return
    this.seAnalyticsService.trackEvent('seEvent', { ...this.pageDepths, action: 'pageLoad', event_type: 1 })
  }

  handleClickEvent(event: Event) {
    const target = (event.target as Element).closest('sm-location-link, .filter, button, .sm-button--img')
    if (!target) return

    let action: string
    let eventType: number

    if (target.matches('sm-location-link')) {
      action = 'locationLink'
      eventType = 1
    }
    else if (target.matches('.filter')) {
      action = target.querySelector('select').id // xxxFilter
      eventType = 3
    }
    else { // button, .sm-button--img
      action = camelCase(target.textContent.trim() || target.className)
      eventType = 9
    }
    this.seAnalyticsService.trackEvent('seEvent', { ...this.pageDepths, action, event_type: eventType })
  }

}
