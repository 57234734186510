import { Component, OnInit } from '@angular/core'
import { Division, DivisionService } from '../services/division.service'
import { Season } from '../services/season.service'
import { ActivatedRoute, Router } from '@angular/router'
import { setWhile } from '../util/set-while'
import { NoNavService } from '../services/no-nav.service'

@Component({
  selector: 'sm-division-detail',
  templateUrl: './division-detail.component.html',
  styleUrls: ['./division-detail.component.scss']
})
export class DivisionDetailComponent implements OnInit {
  loading = true
  division: Division
  season: Season
  noNav: boolean

  constructor(
    route: ActivatedRoute,
    router: Router,
    private divisionService: DivisionService,
    private noNavService: NoNavService
  ) {
    this.season = Season.current
    if (!route.snapshot.queryParamMap.get('divisionTab')) {
      router.navigate([], { relativeTo: route, queryParams: { divisionTab: 'schedule' }, replaceUrl: true })
    }
    route.params.subscribe(p => this.loadDivision(p.division))
  }

  ngOnInit(): void {
    this.noNav = this.noNavService.get()
  }

  @setWhile('loading')
  async loadDivision(divisionId: string): Promise<void> {
    this.division = await this.divisionService.find(divisionId)
  }
}
