<div [smHideWhenChildActive]="['/teams']">
  <!-- prevents losing filters on nav, set here to allow the routed click on <a> to work -->
  <nav (click)="saveFilters(queryParams)"
       class="sm-nav"
       [class.hidden]="!teamStatPrefModules.length">
    <a [routerLink]="[{ tab: 'playerStats' }]"
       queryParamsHandling="merge"
       routerLinkActive
       [routerLinkActiveOptions]="{ matrixParams: 'subset', queryParams: 'ignored', paths: 'subset', fragment: 'ignored' }"
       #playerStats="routerLinkActive"
       [class.active]="playerStats.isActive">
      Player Stats
    </a>
    <a [routerLink]="[{ tab: 'teamStats' }]"
       queryParamsHandling="merge"
       routerLinkActive
       [routerLinkActiveOptions]="{ matrixParams: 'subset', queryParams: 'ignored', paths: 'subset', fragment: 'ignored' }"
       #teamStats="routerLinkActive"
       [class.active]="teamStats.isActive">
      Team Stats
    </a>
  </nav>

  <header class="sm-header">
    <section #filterSection class="filters">
      <button type="button"
              class="sm-button toggle highlight"
              (click)="filterSection.classList.toggle('expanded')">
        Filters
      </button>
      <div *ngIf="!queryParams.division" class="filter">
        <label for="divisionFilter">Division</label>
        <select class="sm-select"
                [(ngModel)]="queryParams.flight_id"
                id="divisionFilter"
                (change)="setFilters('division')"
                data-cy="division-filter-stats">
          <option [ngValue]="undefined">All Divisions</option>
          <option *ngFor="let division of filters.division"
                  [value]="division.id">{{ division.name }}</option>
        </select>
      </div>
      <div class="filter">
        <label for="teamFilter">Team</label>
        <select class="sm-select"
                [(ngModel)]="queryParams.team_id"
                id="teamFilter"
                (change)="setFilters('team')"
                [disabled]="queryParams.flight_id == undefined"
                data-cy="team-filter-stats">
          <option [ngValue]="undefined">All Teams</option>
          <option *ngFor="let team of filters.team"
                  [value]="team.team_id">{{ team.name }}</option>
        </select>
      </div>
      <div class="filter" [class.hidden]="teamStats.isActive">
        <label for="positionFilter">Position</label>
        <select class="sm-select"
                [(ngModel)]="queryParams.position"
                id="positionFilter"
                (change)="setFilters('position')"
                data-cy="position-filter">
          <option [ngValue]="undefined">All Positions</option>
          <option *ngFor="let position of filters.position"
                  [value]="position.key">{{ position.short_name }}</option>
        </select>
      </div>
      <div class="filter">
        <button (click)="setFilters(null)"
                class="sm-button--text"
                data-cy="reset-filters-stats">
          Reset Filters
        </button>
      </div>
    </section>
  </header>
  <sm-stats-table *ngFor="let prefModule of statPrefModules"
                  [prefModule]="prefModule"
                  [filters]="queryParams"
                  [teamUrl]="teamUrl"
                  [class.hidden]="season.statModules[prefModule.key].type !== (playerStats.isActive ? 'StatModule' : 'TeamStatModule')">
  </sm-stats-table>
</div>

<router-outlet></router-outlet>
