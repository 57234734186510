import { Component, Input } from '@angular/core'
import { Game } from '@services'
import { compact, pick, values } from 'lodash'
import { Memoize } from 'typescript-memoize'

@Component({
  selector: 'sm-location-link',
  templateUrl: './location-link.component.html'
})
export class LocationLinkComponent {

  @Input() game: Game

  @Memoize()
  get isAppleUser(): boolean {
    return /iPad|iPhone|iPod|Mac/.test(navigator.userAgent)
  }

  @Memoize()
  get mapLinkRoot() {
    return this.isAppleUser ? 'https://maps.apple.com/?q=' : 'https://www.google.com/maps/place/'
  }

  @Memoize()
  get mapLink() {
    return this.mapLinkRoot + compact(values(pick(this.game.venue?.address, 'address_1', 'address_2', 'city', 'state', 'country')))
      .join(',')
      .replace(/\s+/g, '+')
  }
}
