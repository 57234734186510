import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { omit, pick, pickBy, sortBy } from 'lodash'
import { Division, DivisionService, Season, Team, TeamService, StatPrefModule, StatModule } from '@services'

export type StatFilterParams = {
  flight_id?: string
  team_id?: string
  position?: string
  division?: string
}

@Component({
  selector: 'sm-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit, OnDestroy {
  @Input() public teamUrl = '/teams'

  season = Season.current
  filters = {
    division: undefined as Division[],
    team: undefined as Team[],
    position: undefined as StatModule[]
  }
  queryParams: StatFilterParams
  statPrefModules: StatPrefModule[]
  teamStatPrefModules: StatPrefModule[]
  routerSubscription: Subscription

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private divisionService: DivisionService,
    private teamService: TeamService
  ) {
    this.setTab()
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) this.setTab()
    })
    this.queryParams = {
      ...omit(this.route.snapshot.params, 'tab'),
    }
    this.setStatModules()
  }

  ngOnInit(): void {
    this.filters.division = sortBy(this.divisionService.getAll(), 'name')
    this.filters.team = sortBy(this.teamService.getAll(), 'name')
    this.filters.position = this.season.statPrefModules
      .map(pm => this.season.statModules[pm.key])
      .filter(pm => this.season.statModules[pm.key].type !== 'TeamStatModule')
    if (this.queryParams.division) this.queryParams.flight_id = this.queryParams.division
    this.setTeamFilter()
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe()
  }

  setTab(): void {
    if (!this.route.snapshot.paramMap.get('tab')) {
      this.router.navigate([{ tab: 'playerStats' }], { relativeTo: this.route, replaceUrl: true, queryParamsHandling: 'merge' })
    }
  }

  setStatModules(): void {
    this.teamStatPrefModules = this.season.statPrefModules.filter(sm => this.season.statModules[sm.key].type === 'TeamStatModule')
    const selectedStatModules = this.season.statPrefModules.filter(sm => sm.key === this.queryParams.position)
    this.statPrefModules = selectedStatModules.length ? selectedStatModules.concat(this.teamStatPrefModules) : this.season.statPrefModules
  }

  setFilters(type: keyof StatisticsComponent['filters'] | null): void {
    if (!type) this.queryParams.flight_id = this.queryParams.team_id = this.queryParams.position = undefined
    if (this.queryParams.division) this.queryParams.flight_id = this.queryParams.division
    if (type === 'division') {
      this.queryParams.team_id = undefined
      this.setTeamFilter()
    }
    const saveParams: { [key: string]: string } = pick(this.queryParams, 'flight_id', 'team_id', 'position')
    if (type === 'position' || !type) this.setStatModules()
    this.saveFilters(saveParams)
    this.queryParams = { ...this.queryParams } // overwrite the object for stats-table's OnChanges hook
  }

  setTeamFilter(): void {
    if (this.queryParams.flight_id) {
      this.filters.team = sortBy(this.teamService.getAll(t => t.flight_id === this.queryParams.flight_id), 'name')
    }
  }

  saveFilters(params: any): void {
    this.router.navigate([
      pickBy({
        ...this.route.snapshot.params,
        ...params
      })
    ], {
      relativeTo: this.route,
      replaceUrl: true,
      queryParamsHandling: 'merge'
    })
  }
}
