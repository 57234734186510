import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router'
import { has } from 'lodash'

@Injectable({ providedIn: 'root' })
export class NoNavService implements CanActivate {
  private noNav: boolean

  public canActivate(routeSnapshot: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    this.noNav = has(routeSnapshot.queryParams, 'noNav')
    if (has(routeSnapshot.queryParams, 'embed')) document.documentElement.classList.add('embed')
    return true
  }

  public get(): boolean {
    return this.noNav
  }
}
