import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import * as moment from 'moment-timezone'
import { DateFilter, ListFiltersComponent } from '../list-card/list-filters.component'
import sortBy from 'lodash/sortBy'
import snakeCase from 'lodash/snakeCase'
import mapKeys from 'lodash/mapKeys'

import {
  Division,
  DivisionService,
  Season,
  MicrositeEvent,
  MicrositeEventService,
  QueryParams,
  Team,
  TeamService,
  Venue,
  VenueService,
  Collection,
} from '@services'


export enum ScheduleDate {
  Season = 'season',
  Past = 'past',
  Today = '',
  ThisWeek = 'thisWeek',
  NextWeek = 'nextWeek',
  NextMonth = 'nextMonth',
}

@Component({
  selector: 'sm-schedule2',
  templateUrl: './schedule2.component.html',
  styleUrls: ['./schedule2.component.scss']
})
export class Schedule2Component implements OnInit {

  constructor(
    public route: ActivatedRoute,
    private eventService: MicrositeEventService,
    private divisionService: DivisionService,
    private teamService: TeamService,
    private venueService: VenueService
  ) {
    this.season = Season.current
  }
  @Input() public hideDivisionFilter = false
  @Input() public teamUrl = '/teams'
  @ViewChild(ListFiltersComponent) private listFilters: ListFiltersComponent

  public season: Season
  public events: MicrositeEvent[] = []
  public mappedEvents: any[] = []
  public page = 1
  public perPage = 10
  public lastPage = false
  public loading = true // prevents infinite scroll from firing before initial load
  public eventsLoaded = false
  public resetScroll = false
  public emptyStateText: string
  public filters: any = {}
  public divisions: Division[] = []
  public teams: Team[] = []
  public venues: Venue[] = []

  public filterChangePromise: Promise<any>
  public resolveFilterChange: (value: unknown) => void

  public ngOnInit(): void {
    this.divisions = sortBy(this.divisionService.getAll(), 'name')
    this.teams = sortBy(this.teamService.getAll(), 'name')
    this.venues = sortBy(this.venueService.getAll(), 'name')
  }

  public filterChange(filters: any): void {
    this.filters = filters
    // Setting in timeout to run during another digest
    setTimeout(async () => {
      await this.loadPage(1, true)
      if (this.resolveFilterChange) this.resolveFilterChange(undefined)
    })
  }

  public async loadPage(page: number, filtered = false): Promise<void> {
    // early exit when infinite scroll fires
    if (this.lastPage && !filtered) return
    this.loading = true

    const params = {
      page: `${ this.page = page}`,
      per_page: `${this.perPage}`,
      program_id: this.season.id,
      order_by: 'starts_at',
      direction: this.filters.date === DateFilter.Past ? 'desc' : 'asc',
      ...this.filterParams()
    }
    const events = await this.eventService.findAll(params)

    // Find events in the past on initial load if there are no filters set and no future events
    if (events.length || Object.values(this.filters).find(x => !!x)) {
      this.updateEvents(page, events)
    } else {
      const promise = new Promise(resolve => this.resolveFilterChange = resolve)
      this.listFilters.setFilter('date', DateFilter.Past, false) // false = do not update url
      await promise // leave UI in loading state while we reload via filterChange
    }
  }

  private updateEvents(page: number, events: Collection<MicrositeEvent>): void {
    if (page === 1) {
      this.events.length = 0
      this.resetScroll = true
    }
    this.events.push(...events)
    this.mappedEvents = this.events.map(e => this.eventMapper(e))
    const pagination = mapKeys(events.pagination, (value, key) => snakeCase(key))
    // extra checks account for a bug in calendar service
    this.lastPage = !!pagination.last_page || !pagination.total || pagination.current_page >= pagination.total_pages
    this.loading = false
    this.eventsLoaded = true
  }

  public eventMapper(evt: MicrositeEvent): any {
    const division = evt.principals.find(p => p.originator_type === 'division')
    return {
      evt,
      sport_logo: this.season.sport_key,
      displayTimeRange: evt.displayTimeRange,
      timezone: evt.local_timezone,
      all_day_event: evt.all_day_event,
      tbd_time: evt.tbd_time,
      event_type: evt.event_type,
      title: evt.title,
      description: evt.description,
      venue_name: evt.location_name,
      venue_address: evt.venue?.address,
      subvenue_name: evt.location_description,
      flight_name: division?.extended_attributes?.flight_name || evt.division?.name,
      event_teams: this.mapEventTeams(evt.eventTeams),
      home_team: this.mapTeam(evt.homeTeam),
      home_team_score: evt.source?.extended_attributes?.home_team_score,
      home_team_record: evt.homeTeamStandingsRecord,
      away_team: this.mapTeam(evt.awayTeam),
      away_team_score: evt.source?.extended_attributes?.away_team_score,
      away_team_record: evt.awayTeamStandingsRecord,
      start_date: evt.startDate,
      status: evt.status,
    }
  }

  private mapTeam(team: any) {
    // ! Using existing `teams` from instance to get complete [team] info.
    // ? Needed to fetch logo object.
    const teamInstance = this.teams.find(({ id }) => team?.originator_id === id) ?? null

    return {
      id:  team?.originator_id || '',
      isOutside: team?.extended_attributes?.outside_team || false,
      name: team?.extended_attributes?.name || 'TBD',
      primary_color: team?.extended_attributes?.primary_color,
      logo: teamInstance?.logo,
    }
  }

  private mapEventTeams(teams: any[]) {
    if (!teams) return
    return teams.map(t => ({ id: t.originator_id, name: t.extended_attributes.name }))
  }

  // Convert filters to params
  private filterParams(): QueryParams {
    return {
      ...this.baseParams(),
      ...this.dateParams(),
    }
  }

  private baseParams(): QueryParams {
    const { division, team, location, type } = this.filters
    const params = {} as QueryParams
    if (division) params.flight_id = division
    if (team) params.team_id = team
    if (location) params.venue_id = location
    if (type) params.event_type = type
    return params
  }

  private dateParams(): QueryParams {
    const date = this.filters.date
    const today = moment().tz(this.season.timezone).startOf('day')
    const thisWeek = today.clone().startOf('week')
    const nextWeek = thisWeek.clone().add({ week: 1 })
    const nextMonth = today.clone().startOf('month').add({ month: 1 })

    switch (date || DateFilter.Today) {
      case DateFilter.Season: return {}
      case DateFilter.Past: return { ends_at: today.toJSON() }
      case DateFilter.Today: return { starts_at: today.toJSON() }
      case DateFilter.ThisWeek: return { starts_at: thisWeek.toJSON(), ends_at: nextWeek.toJSON() }
      case DateFilter.NextWeek: return { starts_at: nextWeek.toJSON(), ends_at: nextWeek.clone().add({ week: 1 }).toJSON() }
      case DateFilter.NextMonth: return { starts_at: nextMonth.toJSON(), ends_at: nextMonth.clone().add({ month: 1 }).toJSON() }
      default: return { starts_at: moment.tz(date, this.season.timezone).toJSON() }
    }
  }
}
