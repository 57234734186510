import { Injectable } from '@angular/core'
import * as Color from 'color'

interface ColorPalette {
  accent: Color
  accentBG: Color
  colorOnBG: Color
  textOnBG: Color
}

interface ColorMap {
  [name: string]: Color
}

const BLACK = new Color('#000000')
const WHITE = new Color('#ffffff')
const MIN_CONTRAST = 4.5

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  private style = document.documentElement.style
  private query = window.location.search

  public init() {
    this.setColors(this.queryColors())
  }

  private setColors(colors: ColorMap): void {
    if (!Object.values(colors).filter(x => x).length) return

    const colorPalette = this.colorPalette(colors)

    this.setColor('--accent-color', colorPalette.accent.hex())
    this.setColor('--accent-bg', colorPalette.accentBG.hex())
    this.setColor('--accent-colored-text', colorPalette.colorOnBG.hex())
    this.setColor('--accent-text', colorPalette.textOnBG.hex())
  }

  private colorPalette(colors: ColorMap): ColorPalette {
    const useLink = colors.link.chroma() > 10 || colors.accent.chroma() < 20
    const accent = this.forceContrast(useLink ? colors.link : colors.accent)
    const accentBG = colors.link
    let colorOnBG = colors.accent
    let textOnBG = WHITE

    // Reverse text on light backgrounds
    if (accentBG.contrast(textOnBG) < MIN_CONTRAST) textOnBG = BLACK
    // Genrate a text color from link if accent does not work well
    if (accentBG.contrast(colorOnBG) < MIN_CONTRAST || (colorOnBG.chroma() < 30 && colorOnBG.contrast(textOnBG) < 3)) {
      // Prefer accent color as a base if it has some saturation and BG does not
      const useAccentBg = accentBG.chroma() > 10 || accent.chroma() < 20
      const targetColor = useAccentBg ? accentBG : accent
      colorOnBG = targetColor.lightness(accentBG.isDark() ? 80 : 20)
    }

    return { accent, accentBG, colorOnBG, textOnBG }
  }

  private queryColors(): ColorMap {
    let accent = this.queryColor('accent')
    let link = this.queryColor('link')

    link = link || accent
    accent = accent || link

    return { link, accent }
  }

  private queryColor(name: string): Color {
    const regex = new RegExp(`${name}=([0-9a-f]{6})`, 'i')
    const match = this.query.match(regex)
    return match ? new Color(`#${match[1]}`) : undefined
  }

  private setColor(prop: string, hex: string): void {
    this.style.setProperty(prop, hex)
  }

  private forceContrast(color: Color): Color {
    if (color.contrast(WHITE) >= MIN_CONTRAST) return color
    return this.forceContrast(color.lightness(color.lightness() - 1))
  }

  // TESING THEMES //////////////////////////////


  // public siteThemes = [

  //     [
  //       "181593",
  //       "fdb513",
  //     ],
  //     [
  //       "012b5d",
  //       "6f6f6f",
  //     ],
  //     [
  //       "001ea0",
  //       "666666",
  //     ],
  //     [
  //       "1c316d",
  //       "b6b5b7",
  //     ],
  //     [
  //       "e10505",
  //       "0848dd",
  //     ],
  //     [
  //       "328e0b",
  //       "ff7300",
  //     ],
  //     [
  //       "3228aa",
  //       "24b1e0",
  //     ],
  //     [
  //       "000000",
  //       "17785b",
  //     ],
  //     [
  //       "000435",
  //       "b59410",
  //     ],
  //     [
  //       "00c7fd",
  //       "ffffff",
  //     ],
  //     [
  //       "1c67fd",
  //       "f6cf09",
  //     ],
  //     [
  //       "000000",
  //       "831100",
  //     ],
  //     [
  //       "669EFF",
  //       "00003D",
  //     ],
  //     [
  //       "000000",
  //       "838382",
  //     ],
  //     [
  //       "000000",
  //       "919191",
  //     ],
  //     [
  //       "c76fce",
  //       "606860",
  //     ],
  //     [
  //       "040307",
  //       "c7c7c7",
  //     ],
  //     [
  //       "b542cd",
  //       "f6fa00",
  //     ],
  //     [
  //       "004ecc",
  //       "f5e10a",
  //     ],
  //     [
  //       "2c1376",
  //       "ff8647",
  //     ],
  //     [
  //       "9fa1a3",
  //       "0918ec",
  //     ],
  //     [
  //       "0011ff",
  //       "ffffff",
  //     ],
  //     [
  //       "9bddff",
  //       "ff0000",
  //     ],
  //     [
  //       "f99810",
  //       "383838",
  //     ],
  //     [
  //       "ff2929",
  //       "000000",
  //     ],
  //     [
  //       "0d97e7",
  //       "b5bfbf",
  //     ],
  //     [
  //       "f66000",
  //       "000000",
  //     ],
  //     [
  //       "000000",
  //       "3a88fe",
  //     ],
  //     [
  //       "000000",
  //       "4cbb17",
  //     ],
  //     [
  //       "000000",
  //       "000000",
  //     ],
  //     [
  //       "d71412",
  //       "000000",
  //     ],
  //     [
  //       "000000",
  //       "ff0000",
  //     ],
  //     [
  //       "e41619",
  //       "231f20",
  //     ],
  //     [
  //       "99dfec",
  //       "fbfcfd",
  //     ],
  //     [
  //       "2f304b",
  //       "2b502b",
  //     ],
  //     [
  //       "1c295a",
  //       "55565a",
  //     ],
  //     [
  //       "28369f",
  //       "f5e10a",
  //     ],
  //     [
  //       "000000",
  //       "ffffff",
  //     ],
  //     [
  //       "ff75e1",
  //       "969696",
  //     ],
  //     [
  //       "0253d4",
  //       "e7e008",
  //     ],
  //     [
  //       "000000",
  //       "ff0000",
  //     ],
  //     [
  //       "258b43",
  //       "3f3f3f",
  //     ],
  //     [
  //       "500878",
  //       "500878",
  //     ],
  //     [
  //       "0e204e",
  //       "aec2e5",
  //     ],
  //     [
  //       "79ade0",
  //       "c0c0c0",
  //     ],
  //     [
  //       "000080",
  //       "9bddff",
  //     ],
  //     [
  //       "df7a07",
  //       "0a0000",
  //     ],
  //     [
  //       "000000",
  //       "fe42ff",
  //     ],
  //     [
  //       "000000",
  //       "e51837",
  //     ],
  //     [
  //       "344065",
  //       "4ac8f2",
  //     ],
  //     [
  //       "ff5500",
  //       "000000",
  //     ],
  //     [
  //       "013f94",
  //       "ffffff",
  //     ],
  //     [
  //       "ef0682",
  //       "a204dc",
  //     ],
  //     [
  //       "000000",
  //       "189a2e",
  //     ],
  //     [
  //       "013f84",
  //       "f8ad1b",
  //     ],
  //     [
  //       "24a2cc",
  //       "1f9cdb",
  //     ],
  //     [
  //       "080a9d",
  //       "fd0a0a",
  //     ],
  //     [
  //       "000000",
  //       "f27131",
  //     ],
  //     [
  //       "000000",
  //       "ff0101",
  //     ],
  //     [
  //       "296137",
  //       "e50606",
  //     ],
  //     [
  //       "000000",
  //       "f6ff00",
  //     ],
  //     [
  //       "001840",
  //       "f5c400",
  //     ],
  //     [
  //       "669c35",
  //       "ffffff",
  //     ],
  //     [
  //       "000000",
  //       "d5f72b",
  //     ],
  //     [
  //       "4ebc1b",
  //       "000000",
  //     ],
  //     [
  //       "73000a",
  //       "0b2240",
  //     ],
  //     [
  //       "2b2d45",
  //       "95b1f4",
  //     ],
  //     [
  //       "000000",
  //       "df220d",
  //     ],
  //     [
  //       "11407e",
  //       "000000",
  //     ],
  //     [
  //       "000000",
  //       "750a10",
  //     ],
  //     [
  //       "06396f",
  //       "0271d9",
  //     ],
  //     [
  //       "643296",
  //       "666666",
  //     ],
  //     [
  //       "541859",
  //       "dbbb1f",
  //     ],
  //     [
  //       "0054a4",
  //       "ffd457",
  //     ],
  //     [
  //       "050bc2",
  //       "f8162d",
  //     ],
  //     [
  //       "1f0047",
  //       "aa985d",
  //     ],
  //     [
  //       "0daeee",
  //       "d2ae6d",
  //     ],
  //     [
  //       "2708c4",
  //       "070808",
  //     ],
  //     [
  //       "000000",
  //       "d92417",
  //     ],
  //     [
  //       "3f3c3b",
  //       "f8b90d",
  //     ],
  //     [
  //       "040f62",
  //       "fbc604",
  //     ],
  //     [
  //       "561010",
  //       "767d7d",
  //     ],
  //     [
  //       "043267",
  //       "dcdcdc",
  //     ],
  //     [
  //       "f00928",
  //       "e01941",
  //     ],
  //     [
  //       "050505",
  //       "0565ff",
  //     ],
  //     [
  //       "000000",
  //       "7d777d",
  //     ],
  //     [
  //       "0d31e3",
  //       "f5f6f9",
  //     ],
  //     [
  //       "000000",
  //       "c2b061",
  //     ],
  //     [
  //       "012e72",
  //       "c9102f",
  //     ],
  //     [
  //       "ff0000",
  //       "021ba3",
  //     ],
  //     [
  //       "000000",
  //       "285ff4",
  //     ],
  //     [
  //       "000000",
  //       "e8d717",
  //     ],
  //     [
  //       "61191c",
  //       "767d7d",
  //     ],
  //     [
  //       "842dbe",
  //       "f3e012",
  //     ],
  //     [
  //       "0c2040",
  //       "00985a",
  //     ],
  //     [
  //       "4c574c",
  //       "575252",
  //     ],
  //     [
  //       "0d0d0d",
  //       "626262",
  //     ],
  //     [
  //       "c4b454",
  //       "111212",
  //     ],
  //     [
  //       "222222",
  //       "ffe600",
  //     ],
  //     [
  //       "154823",
  //       "ffffff",
  //     ],
  //     [
  //       "5ecef3",
  //       "f5f92f",
  //     ],
  //     [
  //       "302e8e",
  //       "65ee2b",
  //     ],
  //     [
  //       "000000",
  //       "fd923a",
  //     ],
  //     [
  //       "333333",
  //       "ffe600",
  //     ],
  //     [
  //       "223e99",
  //       "f0cc01",
  //     ],
  //     [
  //       "63b629",
  //       "002043",
  //     ],
  //     [
  //       "24168d",
  //       "892941",
  //     ],
  //     [
  //       "0dc3e7",
  //       "22d60a",
  //     ],
  //     [
  //       "471a1a",
  //       "767d7d",
  //     ],
  //     [
  //       "b00303",
  //       "0c063c",
  //     ],
  //     [
  //       "000000",
  //       "d0b502",
  //     ],
  //     [
  //       "ffab01",
  //       "000000",
  //     ],
  //     [
  //       "54a658",
  //       "f5e000",
  //     ],
  //     [
  //       "48afdb",
  //       "f5f6f4",
  //     ],
  //     [
  //       "213c93",
  //       "da3736",
  //     ],
  //     [
  //       "000000",
  //       "f40606",
  //     ],
  //     [
  //       "002e7a",
  //       "ff6a00",
  //     ],
  //     [
  //       "070ff2",
  //       "f4870b",
  //     ],
  //     [
  //       "2735ff",
  //       "767d7d",
  //     ],
  //     [
  //       "f19009",
  //       "440467",
  //     ],
  //     [
  //       "5e1d78",
  //       "6ca9d8",
  //     ],
  //     [
  //       "105d1d",
  //       "ffcb08",
  //     ],
  //     [
  //       "000080",
  //       "ff2400",
  //     ],
  //     [
  //       "000000",
  //       "ff2600",
  //     ],
  //     [
  //       "474747",
  //       "a98484",
  //     ],
  //     [
  //       "ff2600",
  //       "919191",
  //     ],
  //     [
  //       "061e56",
  //       "bd0000",
  //     ],
  //     [
  //       "002e7a",
  //       "666666",
  //     ],
  //     [
  //       "c7661f",
  //       "335aa6",
  //     ],
  //     [
  //       "28c3a9",
  //       "676862",
  //     ],
  //     [
  //       "4d91ea",
  //       "f3e11b",
  //     ],
  //     [
  //       "f20707",
  //       "000000",
  //     ],
  //     [
  //       "050505",
  //       "e3e3e3",
  //     ],
  //     [
  //       "f50a0a",
  //       "050505",
  //     ],
  //     [
  //       "54adfb",
  //       "ffffff",
  //     ],
  //     [
  //       "ff9300",
  //       "0061ff",
  //     ],
  //     [
  //       "000000",
  //       "73000a",
  //     ],
  //     [
  //       "9900ff",
  //       "000000",
  //     ],
  //     [
  //       "252527",
  //       "234757",
  //     ],
  //     [
  //       "26b2f7",
  //       "0d0d0d",
  //     ],
  //     [
  //       "e32400",
  //       "000000",
  //     ],
  //     [
  //       "03893f",
  //       "000000",
  //     ],
  //     [
  //       "1506ea",
  //       "f4eb01",
  //     ],
  //     [
  //       "960606",
  //       "eb5f09",
  //     ],
  //     [
  //       "2f07f2",
  //       "e02b0b",
  //     ],
  //     [
  //       "000000",
  //       "00ff00",
  //     ],
  //     [
  //       "000000",
  //       "fcfcfc",
  //     ],
  //     [
  //       "4700b3",
  //       "000000",
  //     ],
  //     [
  //       "000000",
  //       "ffffff",
  //     ],
  //     [
  //       "f90606",
  //       "9d9b9b",
  //     ],
  //     [
  //       "2f401f",
  //       "d6e1b4",
  //     ],
  //     [
  //       "b31e1e",
  //       "3db4d1",
  //     ],
  //     [
  //       "3524a3",
  //       "d1cb1f",
  //     ],
  //     [
  //       "000000",
  //       "fc1303",
  //     ],
  //     [
  //       "2ded5e",
  //       "1825ef",
  //     ],
  //     [
  //       "ff6600",
  //       "ffffff",
  //     ],
  //     [
  //       "000000",
  //       "7e7777",
  //     ],
  //     [
  //       "781417",
  //       "848283",
  //     ],
  //     [
  //       "949098",
  //       "283b86",
  //     ],
  //     [
  //       "609bfb",
  //       "0c297d",
  //     ],
  //     [
  //       "004185",
  //       "999999",
  //     ],
  //     [
  //       "47256d",
  //       "f8c721",
  //     ],
  //     [
  //       "000000",
  //       "fcb514",
  //     ],
  //     [
  //       "53c6bc",
  //       "606860",
  //     ],
  //     [
  //       "000000",
  //       "0061fd",
  //     ],
  //     [
  //       "8ab3f4",
  //       "000000",
  //     ],
  //     [
  //       "707070",
  //       "ffffff",
  //     ],
  //     [
  //       "38571a",
  //       "ffffff",
  //     ],
  //     [
  //       "a20606",
  //       "f2e3e3",
  //     ],
  //     [
  //       "008e00",
  //       "727275",
  //     ],
  //     [
  //       "0b35e0",
  //       "ead02a",
  //     ],
  //     [
  //       "12e10e",
  //       "050505",
  //     ],
  //     [
  //       "000000",
  //       "dedede",
  //     ],
  //     [
  //       "3869b7",
  //       "121212",
  //     ],
  //     [
  //       "000000",
  //       "07b7da",
  //     ],
  //     [
  //       "3e6833",
  //       "170909",
  //     ],
  //     [
  //       "000000",
  //       "990000",
  //     ],
  //     [
  //       "e40749",
  //       "00ff40",
  //     ],
  //     [
  //       "000000",
  //       "ec2028",
  //     ],
  //     [
  //       "ca1829",
  //       "17773c",
  //     ],
  //     [
  //       "000000",
  //       "0008fa",
  //     ],
  //     [
  //       "6cace4",
  //       "f2a900",
  //     ],
  //     [
  //       "000000",
  //       "f3f722",
  //     ],
  //     [
  //       "4f7a28",
  //       "ffffff",
  //     ],
  //     [
  //       "000000",
  //       "0061ff",
  //     ],
  //     [
  //       "999494",
  //       "0a0a0a",
  //     ],
  //     [
  //       "000000",
  //       "40f2ef",
  //     ],
  //     [
  //       "0056d6",
  //       "ffffff",
  //     ],
  //     [
  //       "000000",
  //       "eb2246",
  //     ],
  //     [
  //       "ffffff",
  //       "ffffff",
  //     ],
  //     [
  //       "000000",
  //       "db3e79",
  //     ],
  //     [
  //       "62b8f9",
  //       "f20707",
  //     ],
  //     [
  //       "1544b2",
  //       "e5d310",
  //     ],
  //     [
  //       "203f6c",
  //       "f78d1e",
  //     ],
  //     [
  //       "000050",
  //       "c4b454",
  //     ],
  //     [
  //       "000000",
  //       "30f915",
  //     ],
  //     [
  //       "000000",
  //       "2baae1",
  //     ],
  //     [
  //       "000000",
  //       "635f67",
  //     ],
  //     [
  //       "ff2600",
  //       "000000",
  //     ],
  //     [
  //       "d5163c",
  //       "082140",
  //     ],
  //     [
  //       "000000",
  //       "616161",
  //     ],
  //     [
  //       "001e57",
  //       "00c7fc",
  //     ],
  //     [
  //       "000000",
  //       "14e13d",
  //     ],
  //     [
  //       "e41111",
  //       "e41111",
  //     ],
  //     [
  //       "000000",
  //       "d51919",
  //     ],
  //     [
  //       "750000",
  //       "ffc31f",
  //     ],
  //     [
  //       "d60c0c",
  //       "000000",
  //     ],
  //     [
  //       "0c00ad",
  //       "f90101",
  //     ],
  //     [
  //       "2b28e2",
  //       "030303",
  //     ],
  //     [
  //       "000000",
  //       "387ec1",
  //     ],
  //     [
  //       "072b97",
  //       "f6f953",
  //     ],
  //     [
  //       "0060aa",
  //       "c80000",
  //     ],
  //     [
  //       "2346d1",
  //       "dc1e1e",
  //     ],
  //     [
  //       "11284a",
  //       "cccccc",
  //     ],
  //     [
  //       "e60000",
  //       "000000",
  //     ],
  //     [
  //       "c11b21",
  //       "666666",
  //     ],
  //     [
  //       "df0707",
  //       "262222",
  //     ],
  //     [
  //       "041067",
  //       "f0dc00",
  //     ],
  //     [
  //       "002e7a",
  //       "fff76b",
  //     ],
  //     [
  //       "194c91",
  //       "c3012a",
  //     ],
  //     [
  //       "12713f",
  //       "3ec4e6",
  //     ],
  //     [
  //       "000000",
  //       "0053a8",
  //     ],
  //     [
  //       "062384",
  //       "666666",
  //     ],
  //     [
  //       "38571a",
  //       "ffffff",
  //     ],
  //     [
  //       "000000",
  //       "379bff",
  //     ],
  //     [
  //       "51088c",
  //       "410b93",
  //     ],
  //     [
  //       "000000",
  //       "ffea00",
  //     ],
  //     [
  //       "4f7a28",
  //       "b51a00",
  //     ],
  //     [
  //       "ba0c2f",
  //       "a89968",
  //     ],
  //     [
  //       "19ae23",
  //       "e98b07",
  //     ]
  // ]

  // private test = setInterval(() => {
  //   const theme = this.siteThemes.shift()
  //   this.siteThemes.push(theme)
  //   const [ link, accent ] = theme.map(c => new Color(`#${c}`))
  //   this.swatch('accent', accent)
  //   this.swatch('link', link)
  //   this.setColors({ link, accent })
  // }, 2000)

  // private swatch(name: string, color: Color): void {
  //   const key = `${name}Swatch`
  //   let swatch = window[key]
  //   if (!swatch) {
  //     swatch = window[key] = document.createElement('div')
  //     swatch.id = key
  //     swatch.style.color = 'white'
  //     swatch.style.width = '50%'
  //     swatch.style.height = '50px'
  //     swatch.style.float = 'left'
  //   }
  //   swatch.innerText = `${name} - ${color.chroma()}`
  //   swatch.style.background = color.hex()
  //   document.body.prepend(swatch)
  // }

  //////////////////////////// END TEST

}
