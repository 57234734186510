import { Injectable, Injector } from '@angular/core'
import { BaseModel, BaseResource } from '@services'
import { environment } from 'src/environments/environment'

// tslint:disable:variable-name
export class PublishTracking extends BaseModel {
  id: string
  program_id: string
  topic: string
  last_published_at: Date
  last_updated_at: Date
  status: string
}
// tslint:enable:variable-name

@Injectable({
  providedIn: 'root'
})
export class PublishTrackingService extends BaseResource<PublishTracking> {
  public readonly endpoint = `${ environment.apiPath }/publish_tracking`

  constructor(protected injector: Injector) {
    super(PublishTracking, injector)
  }
}
