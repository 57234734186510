<div [smHideWhenChildActive]>

  <header class="sm-header">

    <section #filterSection class="filters">
      <button type="button"
              class="sm-button toggle highlight"
              (click)="filterSection.classList.toggle('expanded')">
        Filters
      </button>
      <div class="filter">
        <label for="divisionFilter">Division</label>
        <select class="sm-select"
                [(ngModel)]="queryParams.flight_id"
                id="divisionFilter"
                (change)="setFilters('division')"
                data-cy="division-filter">
          <option [ngValue]="undefined">All Divisions</option>
          <option *ngFor="let division of filters.division"
                  [value]="division.id">{{ division.name }}</option>
        </select>
      </div>
      <div class="filter">
        <label for="teamFilter">Team</label>
        <select class="sm-select"
                [(ngModel)]="queryParams.team_id"
                id="teamFilter"
                (change)="setFilters('team')"
                [disabled]="queryParams.flight_id == undefined"
                data-cy="team-filter">
          <option [ngValue]="undefined">All Teams</option>
          <option *ngFor="let team of filters.team"
                  [value]="team.team_id">{{ team.name }}</option>
        </select>
      </div>
      <div class="filter">
        <label for="venueFilter">Location</label>
        <select class="sm-select"
                [(ngModel)]="queryParams.venue_id"
                id="venueFilter"
                (change)="setFilters('venue')"
                data-cy="location-filter">
          <option [ngValue]="undefined">All Locations</option>
          <option *ngFor="let venue of filters.venue"
                  [value]="venue.id">{{ venue.name }}</option>
        </select>
      </div>
      <div class="filter">
        <button (click)="setFilters(null)"
                class="sm-button--text"
                data-cy="reset-filters-button">
          Reset Filters
        </button>
      </div>
    </section>

  </header>

  <div class="games table">
    <div class="header">
      <div class="prev">
        <button (click)="changeDate(-7)">
          <span>Previous Week</span>
          <svg height="18" viewBox="0 0 20 36">
            <polyline points="18 2 2 18 18 34" />
          </svg>
        </button>
      </div>
      <h1>
        {{ queryParams.starts_at | momentDate:'MMM D':season.timezone }}
        <span class="dash">–</span>
        {{ queryParams.ends_at | momentDate:'MMM D':season.timezone }}
      </h1>
      <div class="next">
        <button (click)="changeDate(7)">
          <svg height="18" viewBox="0 0 20 36">
            <polyline points="2 2 18 18 2 34" />
          </svg>
          <span>Next Week</span>
        </button>
      </div>
    </div>
    <div class="current-week">
      <button (click)="changeDate(null)"
              class="sm-button--text">
        Go to Current Week
      </button>
    </div>
    <div class="table-header">
      <div class="date">Date/Time</div>
      <div class="matchup">
        <span class="away">Away</span>
        Match Up
        <span class="home">Home</span>
      </div>
      <div class="location">Location</div>
    </div>
    <div *ngIf="loading || !games?.length" class="empty-state spinner">
      <img src="/assets/img/spinner.svg" alt="loading spinner" *ngIf="loading">
      <span *ngIf="!loading">There are no matching games</span>
    </div>
    <div class="table-group"
         [class.hidden]="loading"
         *ngFor="let divisionGames of games | groupBy:'flight_id'">
      <div class="table-group-header">
        <div><b>Division</b> {{ divisionGames[0].flight_name }}</div>
      </div>
      <div class="table-row"
          *ngFor="let game of divisionGames">
        <div class="date">
          <b>{{ game.starts_at | momentDate:'ddd, MMM D':season.timezone }}</b>
          {{ game.starts_at | momentDate:'h:mm A z':season.timezone }}
        </div>
        <div class="matchup {{ game.status }}">
          <div class="away team">
            <div *ngIf="game.awayTeam.id && !game.awayTeam.isOutside; else awayPlaceholder">
              <sm-nav-link
                class="link"
                url="./teams/{{ game.awayTeam.id }}"
                [queryParams]="{ tab: 'schedule' }"
                [label]="game.awayTeam.name"
                [tag]="game.awayTeam.name + '-link'">
              </sm-nav-link>
              <div class="team-standings">{{ game.awayTeam.standingsRecordDisplay }}</div>
            </div>
            <ng-template #awayPlaceholder>
              <a>{{ game.awayTeam.name }}</a>
            </ng-template>
          </div>
          <se-fe-avatar class="team-logo-header" seFeDataType="team"
            [seFeDataSport]="game.awayTeam.season.sport_key"
            [seFeDataImgUrl]="game.awayTeam.logo?.image_urls.medium"
            seFeDataSize="300"
            data-cy="teams-detail-team-logo">
          </se-fe-avatar>
          <div class="scorebox">
            <span class="away score"
                  [class.victor]="game.isVictor(game.awayTeam)">
              {{ game.awayTeamScore }}
            </span>
            <span class="at">AT</span>
            <span class="home score"
                  [class.victor]="game.isVictor(game.homeTeam)">
              {{ game.homeTeamScore }}
            </span>
            <span class="status">
              {{ game.displayStatus }}
            </span>
          </div>
          <se-fe-avatar class="team-logo-header" seFeDataType="team"
            [seFeDataSport]="game.homeTeam.season.sport_key"
            [seFeDataImgUrl]="game.homeTeam.logo?.image_urls.medium"
            seFeDataSize="300"
            data-cy="teams-detail-team-logo">
          </se-fe-avatar>
          <div class="home team">
            <div *ngIf="game.homeTeam.id && !game.homeTeam.isOutside; else homePlaceholder">
              <sm-nav-link
                class="link"
                url="./teams/{{ game.homeTeam.id }}"
                [queryParams]="{ tab: 'schedule' }"
                [label]="game.homeTeam.name"
                [tag]="game.homeTeam.name + '-link'">
              </sm-nav-link>
              <div class="team-standings">{{ game.homeTeam.standingsRecordDisplay }}</div>
            </div>
            <ng-template #homePlaceholder>
              <a>{{ game.homeTeam.name }}</a>
            </ng-template>
          </div>
        </div>
        <div class="location">
          <sm-location-link [game]="game"></sm-location-link>
        </div>
      </div>
    </div>
  </div>

</div>

<router-outlet></router-outlet>
