export function setWhile(setProperty: string): MethodDecorator {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value
    descriptor.value = function(...args: any[]) {
      Reflect.set(this, setProperty, true)
      const result = originalMethod.apply(this, args)
      Promise.resolve(result).finally(() =>
        Reflect.set(this, setProperty, false)
      )
      return result
    }
  }
}
