<div #wrapper class="sm-list-filters__wrapper" (scroll)="updateScrollStyle()">
  <div class="sm-list-filters__padding">
    <se-fe-grid seFeGridOpts="snug spaced left@desktop nowrap">
      <se-fe-grid-cell
        seFeGridOpts="shrink"
        *ngFor="let filter of filters"
        [attr.data-type]="filter.type">
        <se-fe-chip
          [seFeDataText]="textFor(filter)"
          [seFeMenuTrigger]="filter.menu.name"
          [attr.data-cy]="filter.menu.name + '-filter'">{{ labelFor(filter) }}</se-fe-chip>
        <se-fe-menu [seFeDataOptions]="filter.menu" [attr.data-cy]="filter.menu.name + '-filter-menu'"></se-fe-menu>
      </se-fe-grid-cell>
      <se-fe-grid-cell
        seFeGridOpts="shrink"
        [attr.data-scroll]="canScroll"
        [attr.data-type]="'reset'">
        <se-fe-chip
          seFeDataText="Reset Filters"
          seFeDataIconLeft="refresh"
          [seFeDataDisabled]="resetDisabled"
          [seFeDataStyle]="resetDisabled ? 'default' : 'blue-emphasis'"
          (seFeClick)="clearFilters()"
          data-cy="reset-filters">
        </se-fe-chip>
      </se-fe-grid-cell>
      <se-fe-grid-cell seFeGridOpts="shrink" [attr.data-type]="'filters'">
        <se-fe-chip
          seFeDataText="Filters"
          seFeDataIconRight="filter_list"
          [seFeMenuTrigger]="filtersMenuOptions.name"
          (click)="resetFiltersForm()">
        </se-fe-chip>
        <form *ngIf="filtersForm" [formGroup]="filtersForm">
          <se-fe-menu #filtersMenu [seFeDataOptions]="filtersMenuOptions">
            <div class="sm-list-filters__menu" seFeMenuContent>
              <se-fe-grid seFeGridOpts="snug">
                <se-fe-grid-cell *ngFor="let filter of menuFilters" seFeGridOpts="1/1">
                  <se-fe-form-field [seFeDataConfig]="{ label: filter.label, required: true }">
                    <se-fe-form-field-select
                      [formControlName]="filter.key"
                      [seFeDataPlaceholder]="filter.placeholder"
                      [seFeDataOptions]="filter.options"
                      [attr.data-cy]="filter.label + '-filter-options'">
                    </se-fe-form-field-select>
                  </se-fe-form-field>
                </se-fe-grid-cell>
              </se-fe-grid>
            </div>
            <div seFeMenuFooter>
              <se-fe-grid seFeGridOpts="snug">
                <se-fe-grid-cell seFeGridOpts="1/1">
                  <se-fe-button [seFeDataEmphasis]="highEmphasis" [seFeDataFullWidth]="true" (click)="applyFilters()">Apply Filters</se-fe-button>
                </se-fe-grid-cell>
                <se-fe-grid-cell seFeGridOpts="1/1">
                  <se-fe-button [seFeDataFullWidth]="true" (click)="clearFilters()">Clear All Filters</se-fe-button>
                </se-fe-grid-cell>
              </se-fe-grid>
            </div>
          </se-fe-menu>
        </form>
      </se-fe-grid-cell>
    </se-fe-grid>
</div>
</div>
