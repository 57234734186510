import { Injectable, Injector } from '@angular/core'
import { sortBy } from 'lodash'
import { environment } from 'src/environments/environment'
import { BaseModel, BaseResource, Division, DivisionService, Team, TeamService, BelongsTo, HasOne } from '@services'

// tslint:disable:variable-name
export class StandingsTeamRecord extends BaseModel {
  team_id: string
  team_name: string
  team_rank?: number
  team_short_name: string
  values: {
    [key: string]: number
  }
  tiebreak_required?: boolean

  @HasOne(() => TeamService, { localKey: 'team_id', foreignKey: 'team_id', memoize: { tags: ['team'] } })
  team: Team
}
export class Standings extends BaseModel {
  id: string
  program_id: string
  flight_id: string
  standings_node_id: string
  standings_node_type: string

  @BelongsTo(() => DivisionService, { localKey: 'flight_id', memoize: true })
  division: Division

  private _teamRecords: StandingsTeamRecord[]
  get teamRecords(): StandingsTeamRecord[] {
    return this._teamRecords
  }
  set teamRecords(value) {
    if (!value.length && this.division?.teams.length) {
      value = sortBy(this.division.teams, 'name')
        .map(team => Object.assign(new StandingsTeamRecord(this.injector), {
          team_id: team.team_id,
          team_name: team.name,
          team_short_name: team.short_name,
          values: {}
        }))
    }
    let tieStart
    this._teamRecords = value.map((tr, i) => {
      tr = Object.assign(new StandingsTeamRecord(this.injector), tr)
      if (!tr.tiebreak_required) tieStart = undefined
      else if (tieStart === undefined) tieStart = i
      tr.team_rank = (tr.tiebreak_required ? tieStart : i) + 1
      return tr
    })
  }
}
// tslint:enable:variable-name

@Injectable({
  providedIn: 'root'
})
export class StandingsService extends BaseResource<Standings> {
  public readonly endpoint = `${ environment.apiPath }/standings`

  constructor(protected injector: Injector) {
    super(Standings, injector)
  }
}
