<div *ngIf="loading" class="spinner">
  <img src="/assets/img/spinner.svg" alt="loading spinner">
</div>
<div *ngIf="!loading">

  <header [class.hidden]="noNav" class="sm-page-header">
    <div class="back-link" *ngIf="!standalone">
      <a routerLink="../.." data-cy="back-link">Back to {{ parentTitle }}</a>
    </div>
    <se-fe-avatar class="team-logo-header" seFeDataType="team"
      [seFeDataSport]="team.season.sport_key"
      [seFeDataImgUrl]="team.logo?.image_urls.extra_large"
      seFeDataSize="500"
      data-cy="teams-detail-team-logo">
    </se-fe-avatar>
    <div class="team">
      <h1 data-cy="team-name-details">{{ team.name }}</h1>
      <div class="team-standings" data-cy="team-record-details">{{ team.standingsRecordDisplay }}</div>
    </div>

    <aside class="share-actions">
      <button class="copy-link sm-button--img" (click)="copyLink()" data-cy="copy-team-link-button">
        <img src="/assets/img/icon-link.svg" alt="Copy team link">
        <span role="tooltip">{{ showCopyFeedback.link ? 'Copied!' : 'Copy team link' }}</span>
      </button>
      <button class="share-link sm-button--img" *ngIf="showShareButton" (click)="shareLink()">
        <img src="/assets/img/icon-share.svg" alt="Share team link">
        <span role="tooltip">Share team link</span>
      </button>
      <button class="sm-button embed-site" type="button" (click)="showEmbedWindow = true" data-cy="embed-site-button">Embed Site</button>
    </aside>

    <nav class="sm-nav">
      <a routerLink="."
         [queryParams]="{ tab: 'schedule' }"
         routerLinkActive
         #schedule="routerLinkActive"
         [class.active]="schedule.isActive"
         data-cy="schedule-tab">Schedule</a>
      <a routerLink="."
         [queryParams]="{ tab: 'standings' }"
         routerLinkActive
         #standings="routerLinkActive"
         [class.active]="standings.isActive"
         [class.hidden]="!team.season.standings_preferences.enabled"
         data-cy="standings-tab">Standings</a>
      <a routerLink="."
         [queryParams]="{ tab: 'statistics' }"
         routerLinkActive
         #statistics="routerLinkActive"
         [class.active]="statistics.isActive"
         [class.hidden]="!team.season.stat_preferences.enabled"
         data-cy="stats-tab">Statistics</a>
      <a routerLink="."
         [queryParams]="{ tab: 'roster' }"
         routerLinkActive
         #roster="routerLinkActive"
         [class.active]="roster.isActive"
         data-cy="roster-tab">Roster</a>
    </nav>
  </header>

  <div id="schedule" [class.shown]="schedule.isActive">
    <h2 class="sm-page-title">Schedule</h2>
    <div *ngIf="!events.length" class="events table empty-state" data-cy="team-schedule-empty-state">
      No schedule available for this team
    </div>
    <div class="games table" *ngIf="events.length">
      <div class="table-group"
          *ngFor="let dayEvents of this.pagedEvents[page] | groupBy:'displayDate'">
        <div class="table-group-header">
          <div>{{ dayEvents[0].displayDate }}</div>
        </div>
        <div class="table-row"
            *ngFor="let event of dayEvents">
          <div class="date-time">
            <span class="date" data-cy="game-date">{{ event.displayDate + ' @ ' }}</span>
            <span class="time" data-cy="game-time">{{ event.displayTime }}</span>
          </div>
          <div class="opponent" *ngIf="event.event_type === 'game'">
            <span class="at">{{ team.id === (event.homeTeam.originator_id || event.homeTeam.id) ? 'VS' : 'AT' }}</span>
            <se-fe-avatar class="opponent-avatar"
              seFeDataType="team"
              [seFeDataTeamColor]="event.sport_logo === 'other' ? event.otherTeams[0].primary_color : null"
              [seFeDataSport]="event.sport_logo !== 'other' ? event.sport_logo : undefined"
              [seFeDataImgUrl]="event.otherTeams[0].logo?.image_urls.medium"
              seFeDataSize="200"
              data-cy="opponent-team-logo">
            </se-fe-avatar>
            <span class="opponent-name">
              <sm-nav-link
                *ngIf="event.otherTeams[0].id && !event.otherTeams[0].is_outside; else otherPlaceholder"
                url="../{{ event.otherTeams[0].team_id }}"
                queryParamsHandling="preserve"
                [label]="event.otherTeams[0].name"
                tag="opponent-team-link">
              </sm-nav-link>
            </span>
            <ng-template #otherPlaceholder>
              <span data-cy="outside-opponent-name">{{ event.otherTeams[0].name }}</span>
            </ng-template>
          </div>
          <div class="opponent" *ngIf="event.event_type === 'event'">
            <span [ngClass]="{ hide: !event.otherTeams.length }">
              <span class="at">{{ 'WITH' }}</span>
              <ng-container *ngFor="let team of event.otherTeams; let last = last">
                <span class="opponent-name">
                  <sm-nav-link
                    *ngIf="team.id && !team.is_outside; else otherPlaceholder"
                    url="../{{ team.team_id }}"
                    queryParamsHandling="preserve"
                    [label]="team.name"
                    tag="other-team-link">
                  </sm-nav-link>
                  <span *ngIf="!last">, </span>
                </span>
                <ng-template #otherPlaceholder>
                  <span data-cy="other-team-link-outside-opp">{{ team.name }}</span>
                </ng-template>
              </ng-container>
            </span>
          </div>
          <div *ngIf="event.event_type === 'game'" class="game-status {{ event.status }}">
            <span class="status">
              <sm-schedule-status [event]="event"></sm-schedule-status>
            </span>
            <span class="scores">
              <span *ngIf="event.scored" class="us" data-cy="team-score">{{ event.team_score }}</span>
              <span *ngIf="event.scored" class="them" data-cy="opponent-score">{{ event.opponent_score }}</span>
            </span>
            <span class="result" [attr.data-cy]="event.resultAttr">{{ event.result }}</span>
          </div>
          <div *ngIf="event.event_type === 'event'" class="event-details">
            <span class="title" data-cy="event-name-team-details">
              {{ event.title }} <span *ngIf="event.description" data-cy="event-desc-team-details">({{ event.description }})</span>
            </span>
          </div>

          <div class="location">
            <sm-location-link [game]="event" data-cy="event-location-link-team-details"></sm-location-link>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination" *ngIf="events.length">
      <div class="page-size-select">
        <select class="sm-select" [(ngModel)]="pageSize" (change)="setPageSize()">
          <option *ngFor="let n of pageSizes" [value]="n">{{ n }} per page</option>
        </select>
        <span>{{ pageDisplay }}</span>
      </div>
      <span>of {{ events.length }} event{{ events.length === 1 ? '' : 's' }}</span>
      <button class="prev-page" (click)="page = page - 1" [disabled]="!pagedEvents[page - 1]" data-cy="prev-page-team-schedule">
        <svg height="18" viewBox="0 0 20 36">
          <polyline points="18 2 2 18 18 34" />
        </svg>
      </button>
      <button class="next-page" (click)="page = page + 1" [disabled]="!pagedEvents[page + 1]" data-cy="next-page-team-schedule">
        <svg height="18" viewBox="0 0 20 36">
          <polyline points="2 2 18 18 2 34" />
        </svg>
      </button>
    </div>
  </div>

  <div id="standings" [class.shown]="standings.isActive" [class.hidden]="!team.season.standings_preferences.enabled">
    <h2 class="sm-page-title">Standings</h2>
    <sm-division-standings [division]="team.division" teamUrl="."></sm-division-standings>
  </div>

  <div id="statistics" [class.shown]="statistics.isActive" [class.hidden]="!team.season.stat_preferences.enabled">
    <h2 class="sm-page-title">Statistics</h2>
    <sm-stats-table *ngFor="let prefModule of season.statPrefModules"
                    [prefModule]="prefModule"
                    [team]="team">
    </sm-stats-table>
  </div>

  <div id="roster" [class.shown]="roster.isActive" [class.hidden]="!roster.isActive">
    <h2 class="sm-page-title">Roster</h2>
    <sm-team-roster [rosterId]="team.roster_id" [restrictInfo]="restrictPersonalInfo"></sm-team-roster>
  </div>
</div>

<div class="overlay" *ngIf="showEmbedWindow">
  <div class="modal">
    <img class="close" src="/assets/img/times.svg" (click)="showEmbedWindow = false">
    <textarea readonly rows="5" columns="40">{{ embedCode }}</textarea>
    <button class="sm-button" type="button" (click)="copyEmbed()">
      {{ showCopyFeedback.embed ? 'Copied!' : 'Copy Embed' }}
    </button>
  </div>
</div>
