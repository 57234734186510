import { Injectable, Injector } from '@angular/core'
import { environment } from 'src/environments/environment'
import { BaseModel, BaseResource, Season, SeasonService, BelongsTo, HasOne, TeamService, Team } from '@services'
import { Memoize } from 'typescript-memoize'

// tslint:disable:variable-name
@Injectable({
  providedIn: 'root'
})
export class SeasonTeamStat extends BaseModel {
  id: string
  season_id: string
  program_id: string
  team_id: string
  game_type: 'reg' | 'pre' | 'pst' | 'tmt'
  values: { [key: string]: number }
  interval_values: { [key: string]: number }
  created_at: Date
  updated_at: Date
  team_name: string

  @Memoize()
  get name(): string {
    return this.team_name
  }

  @BelongsTo(() => SeasonService, { localKey: 'program_id', memoize: true })
  season: Season

  @HasOne(() => TeamService, { localKey: 'team_id', foreignKey: 'team_id', memoize: { tags: ['team'] } })
  team: Team

  @Memoize({ tags: ['team'] })
  get flight_id(): string {
    return this.team?.flight_id
  }
}
// tslint:enable:variable-name


@Injectable({
  providedIn: 'root'
})
export class SeasonTeamStatsService extends BaseResource<SeasonTeamStat>{
  public readonly endpoint = `${ environment.apiPath }/season_team_stats`

  constructor(protected injector: Injector) {
    super(SeasonTeamStat, injector)
  }
}
