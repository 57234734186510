import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { ListFiltersComponent } from './list-filters.component'
import { ListHeaderComponent } from './list-header.component'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeChipModule } from 'se-fe-chip'
import { SeFeGridModule } from 'se-fe-grid'
import { SeFeMenuModule } from 'se-fe-menu'
import { SeFePositionModule } from 'se-fe-position'
import { SeFeFormFieldModule } from 'se-fe-form-field'
import { SeFeFormFieldSelectModule } from 'se-fe-form-field-select'
import { SeFeFormServiceModule } from 'se-fe-form-service'

@NgModule({
  declarations: [
    ListFiltersComponent,
    ListHeaderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SeFeButtonModule,
    SeFeChipModule,
    SeFeGridModule,
    SeFeMenuModule,
    SeFePositionModule,
    SeFeFormFieldModule,
    SeFeFormFieldSelectModule,
    SeFeFormServiceModule,
  ],
  exports: [
    ListFiltersComponent,
    ListHeaderComponent,
  ]
})
export class ListCardModule { }
