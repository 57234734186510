import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment-timezone'

/**
 * A pipe to defer to Moment for all formatting and handling of timezones.
 * Includes timezone abbreviation support in output.
 */
@Pipe(
  { name: 'momentDate' }
)
export class MomentDatePipe implements PipeTransform {
  transform(
    value: string | Date | moment.Moment,
    format: string = 'll', // mediumDate
    timezone: string = 'US/Central'
  ): string {
    return moment.tz(value, timezone).format(format)
  }
}
