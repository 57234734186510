
<!-- TIME / DIVISION-->
<div class="sm-event__header">
  <span class="sm-event__time" data-cy="event-time">{{ event.displayTimeRange }}</span>
  <div
    class="sm-event__division"
    data-cy="event-division-and-type">
    {{ !crossDivisionScheduling ? event.flight_name : '' }}
  </div>
  <div class="sm-event__status">
    <sm-schedule-status [event]="event"></sm-schedule-status>
  </div>
</div>

<!-- GAME DETAILS-->
<div *ngIf="event.event_type === 'game'" class="sm-event__details--game">
  <span class="sm-event__team--game">
    <se-fe-avatar seFeDataType="team"
      [seFeDataTeamColor]="event.sport_logo === 'other' ? event.away_team.primary_color : null"
      [seFeDataSport]="event.sport_logo !== 'other' ? event.sport_logo : undefined"
      [seFeDataImgUrl]="event.away_team.logo?.image_urls.medium"
      seFeDataSize="200"
      data-cy="away-team-team-logo">
    </se-fe-avatar>
    <div>
      <ng-container *ngIf="event.away_team.id && !event.away_team.isOutside; else awayPlaceholder">
        <sm-nav-link
          url=".{{ teamUrl }}/{{ event.away_team.id  }}"
          [queryParams]="{ tab: 'schedule' }"
          [label]="event.away_team.name"
          tag="away-team-name">
        </sm-nav-link>
      </ng-container>
      <ng-template #awayPlaceholder>
        <span data-cy="outside-away-team">{{ event.away_team.name }}</span>
      </ng-template>
      <div *ngIf="isDesktop || isTablet" class="sm-event__team-record" data-cy="away-team-record">
        {{ event.away_team_record }}
      </div>
    </div>
    <div *ngIf="showScores && isMobile" class="sm-event__scores--mobile">
      {{ event.away_team_score ? event.away_team_score : 0 }}
    </div>
  </span>

  <div class="sm-event__result" >
    <div *ngIf="showScores && !isMobile" class="sm-event__scores" data-cy="game-score">
      {{ event.away_team_score ? event.away_team_score : 0 }}
      -
      {{ event.home_team_score ? event.home_team_score : 0 }}
    </div>
    <div *ngIf="isMobile" class="sm-event__at">AT</div>
    <div class="sm-event__game-status"
      [ngClass]="{ scores: showScores }"
      data-cy="event-status">
      {{ gameStatus }}
    </div>
  </div>

  <span class="sm-event__team--game">
    <se-fe-avatar seFeDataType="team"
      [seFeDataTeamColor]="event.sport_logo === 'other' ? event.home_team.primary_color : null"
      [seFeDataSport]="event.sport_logo !== 'other' ? event.sport_logo : undefined"
      [seFeDataImgUrl]="event.home_team.logo?.image_urls.medium"
      seFeDataSize="200"
      data-cy="home-team-team-logo">
    </se-fe-avatar>
    <div>
      <ng-container *ngIf="event.home_team.id && !event.home_team.isOutside; else homePlaceholder">
        <sm-nav-link
          url=".{{ teamUrl }}/{{ event.home_team.id }}"
          [queryParams]="{ tab: 'schedule' }"
          [label]="event.home_team.name"
          tag="home-team-name">
        </sm-nav-link>
      </ng-container>
      <ng-template #homePlaceholder>
        <span data-cy="outside-home-team">{{ event.home_team.name }}</span>
      </ng-template>
      <div *ngIf="isDesktop || isTablet"
        class="sm-event__team-record"
        data-cy="home-team-record">
        {{ event.home_team_record }}
      </div>
    </div>
    <div *ngIf="showScores && isMobile" class="sm-event__scores--mobile">
      {{ event.home_team_score ? event.home_team_score : 0 }}
    </div>
  </span>
</div>

<!-- EVENT DETAILS -->
<div *ngIf="event.event_type === 'event'" class="sm-event__details--event">
  <div>
    <span class="sm-event__team--event" *ngFor="let team of event.event_teams; last as isLast">
      <ng-container *ngIf="team.id && !team.isOutside; else awayPlaceholder">
        <sm-nav-link
          url=".{{ teamUrl }}/{{ team.id  }}"
          [queryParams]="{ tab: 'schedule' }"
          [label]="team.name"
          tag="team-name-event">
        </sm-nav-link>
      </ng-container>
      <ng-template #awayPlaceholder>
        <a data-cy="outside-team-event">{{ team.name }}</a>
      </ng-template>
      <span *ngIf="!isLast">, </span>
    </span>
  </div>
  <div class="sm-event__description" data-cy="event-title">
    {{ event.title }}
    <span *ngIf="event.description" data-cy="event-description">({{ event.description }})</span>
  </div>
</div>

<!-- LOCATION -->
<div class="sm-event__location">
  <div class="sm-event__location-accordion"
    [attr.aria-expanded]="showMobileAddress ? isExpanded : null"
    [attr.type]="showMobileAddress ? 'button' : undefined"
    (click)="toggleAddress()">
    <se-fe-icon *ngIf="showMobileAddress" seFeDataName="chevron_down" seFeDataSize="500" seFeDataColor="gray-900"></se-fe-icon>
    <se-fe-inline-list>
      <se-fe-inline-list-item *ngIf="event.venue_name" data-cy="venue-name">{{ event.venue_name }}</se-fe-inline-list-item>
      <se-fe-inline-list-item *ngIf="event.venue_name !== event.subvenue_name" data-cy="subvenue-name">{{ event.subvenue_name }}</se-fe-inline-list-item>
      <se-fe-inline-list-item *ngIf="!event.venue_name && !event.subvenue_name" data-cy="tbd-location">TBD</se-fe-inline-list-item>
      <se-fe-inline-list-item *ngIf="!isMobile && address">
        <a *ngIf="isDesktop" href="{{ mapLink }}">{{ address }}</a>
        <span *ngIf="isTablet">{{ address }}</span>
      </se-fe-inline-list-item>
    </se-fe-inline-list>
  </div>
  <div *ngIf="!isDesktop && address" class="sm-event__map-link">
    <se-fe-icon seFeDataName="location_outline" seFeDataSize="500" seFeDataColor="primary"></se-fe-icon>
    <a href="{{ mapLink }}" data-cy="location-address">Map</a>
  </div>
</div>

<div class="sm-event__address--mobile" *ngIf="isExpanded && showMobileAddress">
  {{ address }}
</div>
