import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { EventsEndpointCheckService, NoNavService } from '@services'
import { maxBy } from 'lodash'
@Injectable({
  providedIn: 'root'
})
export class ScheduleRedirect {
  constructor(
    private router: Router,
    private eventsEndpointCheck: EventsEndpointCheckService,
    private noNavService: NoNavService
  ) {
    // noop
  }
  public programId

  public async canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    this.programId = (state.url.match(/\/seasons\/*([^/]*)/))[1]
    const publishedAfterEndpoint = await this.eventsEndpointCheck.publishedAfterEndpoint(this.programId)
    const noNav = this.noNavService.get()

    if (!publishedAfterEndpoint) return true
    return this.router.parseUrl(`/seasons/${ this.programId }/schedule2${ noNav ? '?noNav=true' : '' }`)
  }

}
