import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { setWhile } from '@util'
import { maxBy } from 'lodash'
import { environment } from 'src/environments/environment'
import {
  Division, DivisionService, Season, StandingsModuleService, StandingsService,
  Venue, VenueService, Team, TeamService, PublishTracking, PublishTrackingService,
  SeasonPlayerStatsService, SeasonTeamStatsService, StatModuleService,
  NoNavService
} from '@services'
import moment from 'moment'

@Component({
  selector: 'sm-season',
  templateUrl: './season.component.html',
  styleUrls: ['./season.component.scss']
})
export class SeasonComponent implements OnInit {

  public loading = true
  public programId: string
  public season: Season
  public divisions: Division[]
  public teams: Team[]
  public venues: Venue[]
  public lastPublish: PublishTracking
  public env = environment
  public seasonSubtitle: string[]
  public noNav: boolean
  public scheduleRouterLink: string

  constructor(
    public route: ActivatedRoute,
    private divisionService: DivisionService,
    private teamService: TeamService,
    private venueService: VenueService,
    private standingsService: StandingsService,
    private standingsModuleService: StandingsModuleService,
    private statModuleService: StatModuleService,
    private publishTrackingService: PublishTrackingService,
    private seasonPlayerStatsService: SeasonPlayerStatsService,
    private seasonTeamStatsService: SeasonTeamStatsService,
    private noNavService: NoNavService
  ) {
    route.params.subscribe(p => this.programId = p.programId)
  }

  @setWhile('loading')
  async ngOnInit(): Promise<void> {
    this.season = Season.current
    this.noNav = this.noNavService.get()
    this.scheduleRouterLink = 'schedule2'
    const [divisions, teams, venues] = await Promise.all([
      this.divisionService.findAll({ program_id: this.programId }),
      this.teamService.findAll({ program_id: this.programId }),
      this.venueService.findAll({ org_id: `${ this.season.org_id }` }),
      this.standingsModuleService.findAll(), // load for relations
      this.statModuleService.findAll(),
      this.publishTrackingService.findAll({ program_id: this.programId }),
      this.seasonPlayerStatsService.findAll({ program_id: this.programId }),
      this.seasonTeamStatsService.findAll({ program_id: this.programId })
    ])
    await this.standingsService.findAll({ program_id: this.programId }) // load for relations, wait until after divisions+teams load
    Object.assign(this, { divisions, teams, venues })
    this.lastPublish = maxBy(this.season.publishTracking, 'last_published_at')
    this.updateSubtitle()
  }

  private updateSubtitle(): void {
    const tz = this.season.timezone
    const start = moment.tz(this.season.start_date, tz).format('MMM Do, YYYY')
    const end = moment.tz(this.season.end_date, tz).format('MMM Do, YYYY')
    this.seasonSubtitle = [`${ start } - ${ end }`]

    if (this.lastPublish) {
      this.seasonSubtitle.push(`Last updated at ${ moment.tz(this.lastPublish.last_published_at, tz).format('MMM D, YYYY') }`)
    }

  }

}
