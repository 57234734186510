import { Component, HostBinding, HostListener, Input, OnInit } from '@angular/core'
import { startCase, toLower, compact, pick, values } from 'lodash'
import { Memoize } from 'typescript-memoize'
import { LaunchDarkly } from '../services/launch-darkly.service'
import * as moment from 'moment-timezone'

const TABLET_MIN = 600
const TABLET_MAX = 1023

const titleCase = (str) => startCase(toLower(str))
export enum Status {
  Scheduled = 'scheduled',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Postponed = 'postponed',
  InProgress = 'in_progress',
  Delayed = 'delayed',
}
const AT = 'AT'
const FINAL = 'Final'
@Component({
  selector: 'sm-schedule-event',
  templateUrl: './schedule-event.component.html',
  styleUrls: ['./schedule-event.component.scss']
})
export class ScheduleEventComponent implements OnInit {

  // new mapped event
  @Input() public event: any
  @Input() public teamUrl = '/teams'
  @HostBinding('class.sm-event--game') public get isGame(): boolean { return this.event.event_type === 'game' }
  @HostBinding('class.sm-event--event') public get isEvent(): boolean { return this.event.event_type === 'event' }

  public status: string
  public gameStatus: string
  public isDesktop: boolean
  public isTablet: boolean
  public isMobile: boolean
  public isExpanded = false
  public crossDivisionScheduling = false
  public displayTime = ''

  @Memoize()
  get isAppleUser(): boolean {
    return /iPad|iPhone|iPod|Mac/.test(navigator.userAgent)
  }

  @Memoize()
  get mapLink() {
    let mapLink = this.isAppleUser ? 'https://maps.apple.com/?q=' : 'https://www.google.com/maps/place/'
    mapLink += compact(values(pick(this.event.venue_address, 'address_1', 'address_2', 'city', 'state', 'country')))
      .join(',')
      .replace(/\s+/g, '+')
    return mapLink
  }

  constructor(private ld: LaunchDarkly) {
    // noop
  }

  private get windowWidth(): number {
    return window.innerWidth
  }

  public get address(): string {
    if (!this.event.venue_address) return
    const address = this.event.venue_address
    const addressCityState = compact(values(pick(address, 'address_1', 'address_2', 'city', 'state'))).join(', ')
    return compact([addressCityState, address.postal_code]).join(' ')
  }

  public get showMobileAddress(): boolean {
    return this.isMobile && this.address?.length > 0
  }

  public get showScores(): boolean {
    const evt = this.event
    const unscoredStatus = evt.status !== 'in_progress' && evt.status !== 'completed'
    const hasScores = evt.home_team_score && evt.away_team_score
    if (unscoredStatus || !hasScores) return false
    return true
  }

  public toggleAddress(): void {
    if (!this.address) return
    this.isExpanded = !this.isExpanded
  }

  @HostListener('window:resize')
  public resize(): void {
    this.getIsDesktop()
    this.getIsMobile()
    this.getIsTablet()
  }

  ngOnInit(): void {
    this.crossDivisionScheduling = this.ld.enabled('cross-division-scheduling')
    this.resize()
    this.setStatus()
    this.setGameStatus()
  }

  public getIsMobile(): void { this.isMobile = this.windowWidth < TABLET_MIN }
  public getIsDesktop(): void { this.isDesktop = this.windowWidth > TABLET_MAX }
  public getIsTablet(): void { this.isTablet = !this.isDesktop && !this.isMobile }

  public setStatus(): void {
    this.status = titleCase(this.event.status)
  }

  private setGameStatus(): void {
    this.gameStatus = this.gameStatusDisplay()
  }

  private gameStatusDisplay(): string {
    switch (this.event.status) {
      case Status.InProgress: return titleCase(this.event.status)
      case Status.Completed: return FINAL
      default: return this.isMobile ? '' : AT
    }
  }
}
