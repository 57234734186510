import { Injectable, Injector } from '@angular/core'
import { BaseModel, BaseResource } from '@services'
import { environment } from 'src/environments/environment'

// tslint:disable:variable-name
export class Venue extends BaseModel {
  id: number
  name: string
  notes?: string
  org_id: number
  address: {
    address_1: string
    address_2: string
    city: string
    state: string
    postal_code: string
    country: string
  }
}
// tslint:enable:variable-name

@Injectable({
  providedIn: 'root'
})
export class VenueService extends BaseResource<Venue> {
  public readonly endpoint = `${ environment.apiPath }/venues`

  constructor(protected injector: Injector) {
    super(Venue, injector)
  }
}
