// tslint:disable:max-line-length
import { Injectable, Injector } from '@angular/core'
import * as moment from 'moment-timezone'
import { Memoize } from 'typescript-memoize'
import { environment } from 'src/environments/environment'
import { BaseModel, BaseResource, Venue, VenueService, BelongsTo, DivisionService, Division } from '@services'

export class MicrositeEvent extends BaseModel {
  // tslint:disable:variable-name
  id: string
  title: string
  description: string
  program_id: string
  flight_id: string
  location_name: string
  location_description: string
  location_address: string
  local_timezone: string
  start_date_time: Date
  end_date_time: Date
  event_type: string
  all_day_event: boolean
  tbd_time: boolean
  principals: any
  source: any
  sport_logo: string
  event_teams: any
  status: 'unscheduled' | 'scheduled' | 'in_progress' | 'completed' | 'postponed' | 'cancelled' | 'delayed' | 'deleted'
  venue_address: any
  venue_id: string
  // tslint:disable:variable-name

  @BelongsTo(() => VenueService, { localKey: 'venue_id_num', memoize: true })
  venue: Venue

  @BelongsTo(() => DivisionService, { localKey: 'flightId', memoize: true })
  division: Division

  @Memoize()
  get venue_id_num() {
    return +this.venue_id
  }

  @Memoize()
  get startMoment(): ReturnType<typeof moment.tz> {
    return moment.tz(this.start_date_time, this.local_timezone)
  }

  @Memoize()
  get flightId(): string {
    return this.principals.filter(t => t.type === 'division')[0]?.id
  }

  @Memoize()
  get startDate(): string {
    return this.startMoment.format('YYYY-MM-DD')
  }

  @Memoize()
  get teams(): Array<any> {
    return this.principals.filter(p => p.originator_type === 'season_team' || p.originator_type === 'opponent_season_team')
  }

  @Memoize()
  get eventTeams(): any  {
    if (this.event_type !== 'event') return
    return this.teams
  }

  @Memoize()
  get homeTeam(): any {
    const homeTeamId = this.source.extended_attributes.home_team_id || null
    return this.teams.find(t => t.id === homeTeamId || !t.id)
  }

  @Memoize()
  get awayTeam(): any  {
    const awayTeamId = this.source.extended_attributes.away_team_id || null
    const team =  this.teams.find(t => t.id === awayTeamId || !t.id)

    if (team && team.originator_type === 'opponent_season_team') {
      team.extended_attributes.outside_team = true
    }

    return team
  }

  @Memoize()
  get homeTeamStandingsRecord(): string {
    const records = this.division?.standings?.teamRecords || []
    const record = records.find(r => r.team_id === this.homeTeam?.extended_attributes?.team_id)?.values || { w: 0, l: 0}
    const values = [record.w || 0, record.l || 0]
    if (record.t) values.push(record.t)
    return `(${ values.join(' - ') })`
  }

  @Memoize()
  get awayTeamStandingsRecord(): string {
    const records = this.division?.standings?.teamRecords || []
    const record = records.find(r => r.team_id === this.awayTeam?.extended_attributes?.team_id)?.values || { w: 0, l: 0}
    const values = [record.w || 0, record.l || 0]
    if (record.t) values.push(record.t)
    return `(${ values.join(' - ') })`
  }

  @Memoize()
  public get unscored(): boolean {
    return isNaN(parseInt(this.attrs.away_team_score, 10)) || isNaN(parseInt(this.attrs.home_team_score, 10))
  }

  @Memoize()
  public get displayDate(): string {
    return moment.tz(this.start_date_time, this.local_timezone).format('ddd, MMM D')
  }

  @Memoize()
  public get displayTime(): string {
    if (this.all_day_event) return 'All Day Event'
    if (this.tbd_time) return 'TBD'
    return moment.tz(this.start_date_time, this.local_timezone).format('LT z')
  }

  @Memoize()
  public get displayTimeRange(): string {
    if (this.all_day_event) return 'All Day Event'
    if (this.tbd_time) return 'TBD'
    const startTime = moment.tz(this.start_date_time, this.local_timezone).format('LT z')
    if (!this.end_date_time) {
      return startTime
    }
    const endTime = moment.tz(this.end_date_time, this.local_timezone).format('LT z')
    return `${startTime} - ${endTime}`
  }

  private get attrs(): any {
    return this.source.extended_attributes
  }

  public result(team): string {
    const scoredGame = this.event_type === 'game' && this.status === 'completed' && !this.unscored
    if (!scoredGame) return ''
    const isHome = team.id === this.homeTeam.originator_id
    const awayScore = +this.attrs.away_team_score
    const homeScore = +this.attrs.home_team_score
    if ((isHome && homeScore > awayScore) || (!isHome && awayScore > homeScore)) return 'W'
    if ((isHome && homeScore < awayScore) || (!isHome && awayScore < homeScore)) return 'L'
    return 'T'
  }
}

@Injectable({
  providedIn: 'root',
})
export class MicrositeEventService extends BaseResource<MicrositeEvent> {
  public readonly endpoint = `${ environment.apiPath }/events`

  constructor(protected injector: Injector) {
    super(MicrositeEvent, injector)
  }
}
