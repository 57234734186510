import { Injectable, Injector } from '@angular/core'
import { BaseModel, BaseResource } from '@services'

import { environment } from 'src/environments/environment'

// tslint:disable:variable-name
export class TeamRoster extends BaseModel {
  first_name: string
  full_name: string
  last_name: string
  headshot: any
  id: string
  jersey_number: string
  org_id: number
  participation_status: string
  positions: Array<string>
  roster_id: string
  program_id: string
  roster_type: string
  title: string
}

// tslint:enable:variable-name

@Injectable({ providedIn: 'root' })

export class TeamRosterService extends BaseResource<TeamRoster> {
  public endpoint = `${ environment.apiPath }/roster_players`

  constructor(protected injector: Injector) {
    super(TeamRoster, injector)
  }
}
