import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { DivisionDetailComponent, ScheduleComponent, SeasonComponent, StandingsComponent, StatisticsComponent, TeamDetailComponent } from '@components'
import { LaunchDarkly, SeasonService, ScheduleRedirect, Schedule2Redirect, NoNavService } from '@services'
import { Schedule2Component } from './schedule/schedule2.component'

const routes: Routes = [
  {
    path: 'seasons/:programId',
    component: SeasonComponent,
    canActivate: [SeasonService, LaunchDarkly, NoNavService],
    data: { isRoot: true },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'schedule' },
      {
        path: 'schedule2',
        data: { title: 'Schedule' },
        component: Schedule2Component,
        canActivate: [Schedule2Redirect],
        children: [
          { path: 'teams/:teamId', component: TeamDetailComponent }
        ]
      },
      {
        path: 'schedule',
        data: { title: 'Schedule' },
        component: ScheduleComponent,
        canActivate: [ScheduleRedirect],
        children: [
          { path: 'teams/:teamId', component: TeamDetailComponent }
        ]
      },
      {
        path: 'standings',
        data: { title: 'Standings' },
        component: StandingsComponent,
        children: [
          { path: 'teams/:teamId', component: TeamDetailComponent }
        ]
      },
      {
        path: 'statistics',
        data: { title: 'Statistics' },
        component: StatisticsComponent,
        children: [
          { path: 'teams/:teamId', component: TeamDetailComponent }
        ]
      },
      { path: 'teams/:teamId', component: TeamDetailComponent },
      { path: 'divisions/:division', component: DivisionDetailComponent }
    ]
  }
  // { path: '**', component: PathNotFoundComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
