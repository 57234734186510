import { Component, HostBinding, Input } from '@angular/core'

@Component({
  selector: 'sm-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss']
})
export class ListHeaderComponent {

  @Input() public text = ''
  @Input() public secondaryText = ''
  @HostBinding('class.sm-list-header') public baseClass = true

}
