import { Directive, ElementRef, Input, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { some } from 'lodash'

@Directive({
  selector: '[smHideWhenChildActive]'
})
export class HideWhenChildActiveDirective implements OnInit {
  @Input() smHideWhenChildActive: string[] = ['/teams', '/division']

  private element: HTMLElement

  set childActive(active: boolean) {
    this.element.classList.toggle('hidden', active)
  }

  constructor(private router: Router, private ref: ElementRef) {
    // noop
  }

  ngOnInit(): void {
    this.element = this.ref.nativeElement
    this.childActive = some(this.smHideWhenChildActive, (url) => this.router.routerState.snapshot.url.includes(url))
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.childActive = some(this.smHideWhenChildActive, (url) => (event as NavigationEnd).url.includes(url))
      }
    })
  }

}
