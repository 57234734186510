import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Season, Division, DivisionService } from '@services'
import { sortBy } from 'lodash'

@Component({
  selector: 'sm-standings',
  templateUrl: './standings.component.html',
  styleUrls: ['./standings.component.scss']
})
export class StandingsComponent {
  public selectedDivision?: Division
  public allDivisions: Division[]
  public divisions: Division[]

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private divisionService: DivisionService
  ) {
    this.allDivisions = sortBy(Season.current.divisions, 'name')
    this.selectedDivision = this.divisionService.get(this.activatedRoute.snapshot.params.flight_id)
    this.setDivisions()
  }

  setDivisions(): void {
    this.divisions = this.selectedDivision ? [this.selectedDivision] : this.allDivisions
  }

  setFilters(): void {
    this.setDivisions()
    this.router.navigate([{
      flight_id: this.selectedDivision?.id
    }], {
      relativeTo: this.activatedRoute,
      replaceUrl: true
    })
  }
}
