import { Pipe, PipeTransform } from '@angular/core'
import { groupBy, ValueIteratee, values } from 'lodash'


@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {

  transform<T>(array: T[], iteratee: ValueIteratee<T>): T[][] {
    return values(groupBy(array, iteratee))
  }

}
