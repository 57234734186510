import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { setWhile } from '@util'
import { pick, pickBy, sortBy } from 'lodash'
import * as moment from 'moment-timezone'
import {
  Season, Division, DivisionService, Team, TeamService,
  Venue, VenueService, Game, GameService
} from '@services'

@Component({
  selector: 'sm-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

  loading = true
  season: Season
  filters = {
    division: undefined as Division[],
    team: undefined as Team[],
    venue: undefined as Venue[]
  }
  queryParams: {
    flight_id?: string,
    team_id?: string,
    venue_id?: string, // convert from number
    starts_at: string,
    ends_at: string
  }
  games: Game[]

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private gameService: GameService,
    private divisionService: DivisionService,
    private teamService: TeamService,
    private venueService: VenueService
  ) {
    this.season = Season.current
    this.queryParams = {
      ...this.activatedRoute.snapshot.params,
      ...this.getDates(moment.tz(this.activatedRoute.snapshot.params.starts_at, this.season.timezone).startOf('week'))
    }
    this.setTeamFilter()
  }

  ngOnInit(): void {
    this.filters.division = sortBy(this.divisionService.getAll(), 'name')
    this.filters.venue = sortBy(this.venueService.getAll(), 'name')
    this.loadGames()

  }

  @setWhile('loading')
  async loadGames(): Promise<void> {
    this.games = await this.gameService.findAll(pickBy({
      program_id: this.season.id,
      order_by: 'flight_name,starts_at',
      ...this.queryParams
    }))
  }

  changeDate(diffDays: number | null): void {
    diffDays ||= moment.tz(undefined, this.season.timezone).startOf('week').diff(this.queryParams.starts_at, 'd')
    Object.assign(this.queryParams, this.getDates(moment(this.queryParams.starts_at).add(diffDays, 'd')))
    this.saveFilters({ starts_at: moment(this.queryParams.starts_at).format('YYYY-MM-DD') })
    this.loadGames()
  }

  setFilters(type: keyof ScheduleComponent['filters'] | null): void {
    if (!type) this.queryParams.flight_id = this.queryParams.team_id = this.queryParams.venue_id = undefined
    if (type === 'division') {
      this.queryParams.team_id = undefined
      this.setTeamFilter()
    }
    this.saveFilters(pick(this.queryParams, 'flight_id', 'team_id', 'venue_id'))
    this.loadGames()
  }

  setTeamFilter(): void {
    if (this.queryParams.flight_id) {
      this.filters.team = sortBy(this.teamService.getAll(t => t.flight_id === this.queryParams.flight_id), 'name')
    }
  }

  saveFilters(params: any): void {
    this.router.navigate([
      pickBy({
        ...this.activatedRoute.snapshot.params,
        ...params
      })
    ], {
      relativeTo: this.activatedRoute,
      replaceUrl: true
    })
  }

  private getDates(startDate: Date | moment.Moment): { starts_at: string, ends_at: string } {
    const date = moment.tz(startDate, this.season.timezone)
    return {
      starts_at: date.toJSON(),
      ends_at: date.endOf('week').toJSON()
    }
  }
}
